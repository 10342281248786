import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

import { RouteData, SITE_NAME } from "@sentium/constants";

@Injectable({
  providedIn: "root",
})
export class TitleService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly title: Title,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(SITE_NAME) private readonly siteName: string
  ) {
    this.initRouterEventsSubscription();
  }

  initRouterEventsSubscription() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (
              child.snapshot.data &&
              (child.snapshot.data as RouteData).title
            ) {
              return (child.snapshot.data as RouteData).title;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((key: string | null) => {
        if (key) {
          this.title.setTitle(`${this.siteName} - ${key}`);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
