import { InjectionToken } from "@angular/core";
import { IStorageContract, IEnvironment } from "@sentium/models";

export const SITE_NAME_VALUE = "sentium";
export const SITE_NAME = new InjectionToken<string>("SITE_NAME");

export const ENV_TOKEN = new InjectionToken<IEnvironment>("ENV_TOKEN");

export const STORAGE_LANGUAGE_VALUE = "language";
export const STORAGE_LANGUAGE_KEY = new InjectionToken<string>(
  "STORAGE_LANGUAGE_KEY"
);

export const STORAGE_TOKEN = new InjectionToken<IStorageContract>(
  "STORAGE_TOKEN"
);
