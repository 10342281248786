import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconsService } from "@sentium/services";

@Component({
  selector: "sentium-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon!: string;
  @Input() iconStyle!: { [key: string]: string };

  @Input() disabled = false;

  constructor(readonly iconsService: IconsService) {}
}
