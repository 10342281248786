/* eslint-disable @typescript-eslint/no-explicit-any */
import { MatTableDataSource } from "@angular/material/table";

interface Filters {
  columns: { [field: string]: string };
  global: string;
}

export class DataSource<T> extends MatTableDataSource<T> {
  filters: Filters = { columns: {}, global: "" };

  constructor(data: T[]) {
    super(data);

    this.filterPredicate = (item: any) => {
      const globalMatch = Object.keys(item).some((field) => {
        return item[field]
          .toString()
          .toLocaleLowerCase()
          .includes(this.filters.global);
      });
      const colMatch = !Object.keys(this.filters.columns).reduce(
        (remove, field) => {
          return (
            remove ||
            !item[field]
              .toString()
              .toLocaleLowerCase()
              .includes(this.filters.columns[field])
          );
        },
        false
      );
      return globalMatch && colMatch;
    };
  }
}
