export enum CodeEditorTheme {
  DEFAULT = "default",
  DAY_3024 = "3024-day",
  NIGHT_3024 = "3024-night",
  ABBOTT = "abbott",
  ABCDEF = "abcdef",
  AMBIANCE = "ambiance",
  AYU_DARK = "ayu-dark",
  AYU_MIRAGE = "ayu-mirage",
  BASE16_DARK = "base16-dark",
  BASE16_LIGHT = "base16-light",
  BESPIN = "bespin",
  BLACKBOARD = "blackboard",
  COBALT = "cobalt",
  COLORFORTH = "colorforth",
  DRACULA = "dracula",
  DARCULA = "darcula",
  DUOTONE_DARK = "duotone-dark",
  DUOTONE_LIGHT = "duotone-light",
  ECLIPSE = "eclipse",
  ELEGANT = "elegant",
  ERLANG_DARK = "erlang-dark",
  GRUVBOX_DARK = "gruvbox-dark",
  HOPSCOTCH = "hopscotch",
  ICECODER = "icecoder",
  IDEA = "idea",
  ISOTOPE = "isotope",
  JUEJIN = "juejin",
  LESSER_DARK = "lesser-dark",
  LIQUIBYTE = "liquibyte",
  LUCARIO = "lucario",
  MATERIAL = "material",
  MATERIAL_DARKER = "material-darker",
  MATERIAL_PALENIGHT = "material-palenight",
  MATERIAL_OCEAN = "material-ocean",
  MBO = "mbo",
  MDN_LIKE = "mdn-like",
  MIDNIGHT = "midnight",
  MONOKAI = "monokai",
  MOXER = "moxer",
  NEAT = "neat",
  NEO = "neo",
  NIGHT = "night",
  NORD = "nord",
  OCEANIC_NEXT = "oceanic-next",
  PANDA_SYNTAX = "panda-syntax",
  PARAISO_DARK = "paraiso-dark",
  PARAISO_LIGHT = "paraiso-light",
  PASTEL_ON_DARK = "pastel-on-dark",
  RAILSCASTS = "railscasts",
  RUBYBLUE = "rubyblue",
  SETI = "seti",
  SHADOWFOX = "shadowfox",
  THE_MATRIX = "the-matrix",
  TOMORROW_NIGHT_BRIGHT = "tomorrow-night-bright",
  TOMORROW_NIGHT_EIGHTIES = "tomorrow-night-eighties",
  TTCN = "ttcn",
  TWILIGHT = "twilight",
  VIBRANT_INK = "vibrant-ink",
  XQ_DARK = "xq-dark",
  XQ_LIGH = "xq-light",
  YETI = "yeti",
  YONCE = "yonce",
  ZENBURN = "zenburn",
}
