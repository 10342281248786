import { createAction, props } from "@ngrx/store";
import { StepId } from "@sentium/models";

export enum GeneralAction {
  GENERAL_LOADING = "[GENERAL] GENERAL_LOADING",
  SET_TOKEN = "[GENERAL] SET_TOKEN",
  SET_PROJECT_ID = "[GENERAL] SET_PROJECT_ID",
  SET_INIT_STEPPER = "[GENERAL] SET_INIT_STEPPER",
  CHANGE_ACTIVE_STEP = "[GENERAL] CHANGE_ACTIVE_STEP",
}

export const generalIsLoading = createAction(
  GeneralAction.GENERAL_LOADING,
  props<{ flag: boolean }>()
);

export const setToken = createAction(
  GeneralAction.SET_TOKEN,
  props<{ token: string }>()
);

export const setProjectId = createAction(
  GeneralAction.SET_PROJECT_ID,
  props<{ id: string }>()
);

export const setInitStepper = createAction(GeneralAction.SET_INIT_STEPPER);

export const changeActiveStep = createAction(
  GeneralAction.CHANGE_ACTIVE_STEP,
  props<{ id: StepId }>()
);
