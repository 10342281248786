import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from "@angular/core";

import { EMPTY_LINE } from "@sentium/constants";

import { cloneDeep } from "lodash";
import { CodeEditorOptions } from "../model";
import { DEFAULT_EDITOR_CONFIG } from "../tokens";

@Component({
  selector: "sentium-code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.scss"],
})
export class CodeEditorComponent {
  private _options!: Partial<CodeEditorOptions>;

  editorContent!: string | null;

  @Input() useDefault = false;

  @Input() set options(v: Partial<CodeEditorOptions>) {
    this._options = this.useDefault
      ? cloneDeep(this.defaultConfig)
      : cloneDeep(v);
  }
  get options(): Partial<CodeEditorOptions> {
    return this.useDefault ? this.defaultConfig : this._options;
  }

  @Input() set value(v: string | null) {
    if (!v) {
      this.editorContent = EMPTY_LINE;
    }
    this.editorContent = v;
  }

  @Output() editorContentEvent = new EventEmitter<string>();

  @Input() invalid!: boolean;

  constructor(
    @Optional()
    @Inject(DEFAULT_EDITOR_CONFIG)
    private readonly defaultConfig: Partial<CodeEditorOptions>
  ) {}

  editorContentChange(v: string) {
    this.editorContentEvent.emit(v);
  }
}
