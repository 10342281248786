<div class="box">
  <div class="box-header">
    <div class="row g-0 align-items-baseline">
      <div class="col-5">
        <div class="block__title mb-0 me-8">Avaliable Metadata</div>
      </div>
      <div class="col-7">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Filter</mat-label>
            <input
              matInput
              type="text"
              placeholder="Filter"
              [formControl]="filter"
            />
            <button matSuffix mat-icon-button>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="box-main">
    <div class="input-wrapper mb-24" (click)="addCustomMeta()">
      <input
        type="text"
        class="input w-100"
        placeholder="Custom meta"
        [formControl]="customMeta"
      />
      <div
        [ngClass]="{
          'add-metadata-valid': customMeta.valid,
          'add-metadata-invalid': customMeta.invalid
        }"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99967 3.33301C9.77866 3.33301 9.5667 3.42081 9.41042 3.57709C9.25414 3.73337 9.16634 3.94533 9.16634 4.16634V9.16634H4.16634C3.94533 9.16634 3.73337 9.25414 3.57709 9.41042C3.42081 9.5667 3.33301 9.77866 3.33301 9.99967C3.33301 10.2207 3.42081 10.4326 3.57709 10.5889C3.73337 10.7452 3.94533 10.833 4.16634 10.833H9.16634V15.833C9.16634 16.054 9.25414 16.266 9.41042 16.4223C9.5667 16.5785 9.77866 16.6663 9.99967 16.6663C10.2207 16.6663 10.4326 16.5785 10.5889 16.4223C10.7452 16.266 10.833 16.054 10.833 15.833V10.833H15.833C16.054 10.833 16.266 10.7452 16.4223 10.5889C16.5785 10.4326 16.6663 10.2207 16.6663 9.99967C16.6663 9.77866 16.5785 9.5667 16.4223 9.41042C16.266 9.25414 16.054 9.16634 15.833 9.16634H10.833V4.16634C10.833 3.94533 10.7452 3.73337 10.5889 3.57709C10.4326 3.42081 10.2207 3.33301 9.99967 3.33301Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="metadata-tree"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div
          class="w-100 d-flex g-10 justify-content-between align-items-center"
        >
          <div>{{ node.name }}</div>
          <sentium-icon
            *ngIf="this.dataSource.data[0].children?.length"
            (click)="addMeta(node)"
            icon="add"
            [iconStyle]="{ color: '#ee702a' }"
          ></sentium-icon>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          {{ node.name }}
        </div>
        <div
          [ngClass]="{
            'metadata-tree-invisible': !treeControl.isExpanded(node)
          }"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
