import { MatDateFormats } from "@angular/material/core";

export const DATE_FIRST_NANOSECOND = "T00:00:00.000000";
export const DATE_LAST_NANOSECOND = "T23:59:00.999999";
export const DATE_FORMAT_WITH_NANOSECONDS = "YYYY-MM-DDTHH:mm:ss.SSSSSS";
export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";
export const DEV_DATE_FORMAT = "YYYY-MM-DD";

export const DEFAULT_MAT_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DEFAULT_DATE_FORMAT,
  },
  display: {
    dateInput: DEFAULT_DATE_FORMAT,
    monthYearLabel: DEFAULT_DATE_FORMAT,
    dateA11yLabel: DEFAULT_DATE_FORMAT,
    monthYearA11yLabel: DEFAULT_DATE_FORMAT,
  },
};
