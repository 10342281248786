import { IEnvironment } from "@sentium/models";

export const environment: IEnvironment = {
  production: false,
  compute: "https://compute.api.os.sentium.space/v2.1",
  image: "https://image.api.os.sentium.space/v2",
  volume: "https://volumev3.api.os.sentium.space/v3",
  network: "https://network.api.os.sentium.space/v2.0",
  identity: "https://identity.api.os.sentium.space/v3",
};
