import { Injectable } from "@angular/core";
import { ConfigAsset } from "@sentium/models";
import { config } from "@configs";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  getConfig(): ConfigAsset {
    return config;
  }

  getByKey(key: keyof ConfigAsset): any {
    return config[key];
  }
}
