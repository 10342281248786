import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
} from "@angular/core";
import { MatTab, MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: "sentium-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements AfterViewInit {
  @Input() disableRipple = true;

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ContentChildren(MatTab) tabs!: QueryList<MatTab>;

  @Output() selectedTabChangeEvent = new EventEmitter<MatTabChangeEvent>();

  ngAfterViewInit(): void {
    this.matTabGroup._tabs.reset([...this.tabs, ...this.matTabGroup._tabs]);
    this.matTabGroup._tabs.notifyOnChanges();
  }

  selectedTabChange(tabs: MatTabChangeEvent) {
    this.selectedTabChangeEvent.emit(tabs);
  }
}
