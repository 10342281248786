import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SpinnerSize } from "./spinner.model";

@Component({
  selector: "sentium-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() size: SpinnerSize = "normal";
}
