<div
  class="drag-items-container"
  [ngStyle]="{
    'grid-template-columns': templateColumns,
    'grid-template-rows:': templateRows
  }"
  cdkDropListGroup
>
  <div
    cdkDropList
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  ></div>
  <div
    cdkDropList
    *ngFor="let item of dragItems; trackBy: itemTrackBy"
    (cdkDropListDropped)="dropListDropped()"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  >
    <div cdkDrag class="drag-box" [ngClass]="dragBlockClasses">
      <ng-template ngFor [ngForOf]="[item]" [ngForTemplate]="template">
      </ng-template>
    </div>
  </div>
</div>
