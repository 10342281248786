import { Component, EventEmitter, Input, Output, Self } from "@angular/core";

import { MetadataNode, Metadata } from "@sentium/models";

import { cloneDeep } from "lodash";

import { MetadataContainerService } from "../metadata-container.service";

@Component({
  selector: "sentium-metadata",
  templateUrl: "./metadata.component.html",
  styleUrls: ["./metadata.component.scss"],
  providers: [MetadataContainerService],
  viewProviders: [MetadataContainerService],
})
export class MetadataComponent {
  @Input() set availableMetadata(v: MetadataNode[] | null) {
    if (!v) {
      return;
    }
    this.metadataContainerService.availableMetadata = cloneDeep(v);
    this.metadataContainerService.initAvailableMetadata = cloneDeep(v);
  }

  @Input() set existingMetadata(v: Metadata[] | null) {
    if (!v) {
      return;
    }
    this.metadataContainerService.existingMetadata = cloneDeep(v);
  }

  @Output() metadataChange = new EventEmitter<
    Array<Pick<Metadata, "key" | "value">>
  >();

  @Output() metadataInvalid = new EventEmitter<boolean>();

  constructor(
    @Self() readonly metadataContainerService: MetadataContainerService
  ) {
    this.mock();
  }

  existingMetadataEvent(values: Array<Pick<Metadata, "key" | "value">>): void {
    this.metadataChange.emit(values);
  }

  existingMetadataInvalidWeight(v: boolean): void {
    this.metadataInvalid.emit(v);
  }

  private mock(): void {
    const available: MetadataNode[] = [
      {
        name: "Runtime environment",
        nodeId: "0",
        parentNodeId: "",
        children: [
          {
            name: "Java",
            parentNodeId: "0",
            nodeId: "1-1",
            children: [
              {
                name: "sw_runtime_java_version",
                nodeId: "2-1",
                parentNodeId: "1-1",
              },
            ],
          },
          {
            name: "Perl",
            parentNodeId: "0",
            nodeId: "1-2",
            children: [
              {
                name: "sw_runtime_perl_version",
                nodeId: "2-2",
                parentNodeId: "1-2",
              },
            ],
          },
        ],
      },
    ];

    const existing: Metadata[] = [];
    this.metadataContainerService.availableMetadata = cloneDeep(available);
    this.metadataContainerService.initAvailableMetadata = cloneDeep(available);
    this.metadataContainerService.existingMetadata = existing;

    this.metadataChange.emit(existing);
  }
}
