/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MenuPositionX, MenuPositionY } from "@angular/material/menu";
import {
  BaseFieldDirective,
  ngValueAccessorProvide,
} from "@sentium/common-utils";
import { EMPTY_LINE } from "@sentium/constants";
import { MultiSelectItem } from "@sentium/models";

import { cloneDeep, indexOf } from "lodash";

@Component({
  selector: "sentium-multi-select-menu",
  templateUrl: "./multi-select-menu.component.html",
  styleUrls: ["./multi-select-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvide(MultiSelectMenuComponent)],
})
export class MultiSelectMenuComponent<T> extends BaseFieldDirective {
  private _items!: T & MultiSelectItem[];

  @Input() set items(v: T & MultiSelectItem[]) {
    this._items = cloneDeep(this.mapItems(v));
    this.defineSelectedItems();
  }

  get items(): T & MultiSelectItem[] {
    return this._items;
  }

  @Input() placeholder!: string;
  @Input() yPosition: MenuPositionY = "below";
  @Input() xPosition: MenuPositionX = "before";

  @Output() itemSelectEvent = new EventEmitter<T & MultiSelectItem>();
  @Output() allItemsEvent = new EventEmitter<T & MultiSelectItem[]>();

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  preventDefaultBehaviour($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    if ($event.target) {
      $event.target.classList.toggle("selected");
    }
  }

  selection(item: MultiSelectItem): void {
    const itemClone = cloneDeep({ ...item, selected: !item.selected }) as T &
      MultiSelectItem;
    this.itemSelectEvent.emit(itemClone);
    this._items[indexOf(this._items, item)] = itemClone;
    this.allItemsEvent.emit(this._items);
    this.defineSelectedItems();
    this.cdr.detectChanges();
  }

  private mapItems(v: T & MultiSelectItem[]): T & MultiSelectItem[] {
    return v.map((el) => ({ ...el, selected: Boolean(el?.selected) })) as T &
      MultiSelectItem[];
  }

  private defineSelectedItems(): void {
    const itemsToPreselect: (string | number)[] = cloneDeep(this.items)
      .filter((el) => el?.selected)
      .map((el) => el.value);
    const value =
      itemsToPreselect.length > 0 ? itemsToPreselect.join(", ") : EMPTY_LINE;
    this.valueControl.setValue(value, {
      emitEvent: true,
    });
  }
}
