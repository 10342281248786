import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

import { IStorageContract } from "@sentium/models";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService implements IStorageContract {
  private window: (Window & typeof globalThis) | null;

  private get localStorage() {
    return this.window?.localStorage;
  }

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.window = this.document.defaultView;
  }

  setItem(key: string, value: string): void {
    this.localStorage?.setItem(key, value);
  }

  getItem(key: string) {
    return this.localStorage?.getItem(key);
  }

  clearStorage(): void {
    this.localStorage?.clear();
  }
}
