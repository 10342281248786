import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { MaterialModule } from "@sentium/material";
import { currentLocale } from "@sentium/common-utils";
import { DEFAULT_MAT_FORMATS } from "@sentium/constants";

import { SimpleCalendarComponent } from "./simple-calendar/simple-calendar.component";
import { RangeCalendarComponent } from "./range-calendar/range-calendar.component";

const COMPONENTS = [SimpleCalendarComponent, RangeCalendarComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild(),
  ],
  exports: [...COMPONENTS],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useFactory: currentLocale,
      deps: [TranslateService],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DEFAULT_MAT_FORMATS,
    },
  ],
})
export class CalendarModule {}
