import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RadioItem } from "@sentium/models";

import { cloneDeep } from "lodash";

@Component({
  selector: "sentium-radios-group",
  templateUrl: "./radios-group.component.html",
  styleUrls: ["./radios-group.component.scss"],
})
export class RadiosGroupComponent {
  private _items!: RadioItem[];

  @Input() set items(v: RadioItem[] | null) {
    if (!v) {
      return;
    }
    this._items = cloneDeep(v);
  }
  get items(): RadioItem[] {
    return this._items;
  }

  @Output() itemSelected = new EventEmitter<RadioItem>();

  clicked(item: RadioItem): void {
    this.itemSelected.emit(item);
  }
}
