import { DEFAUL_TRIM_LENGTH, EMPTY_TABLE_VALUE } from "@sentium/constants";

export function trimStr(
  str: string,
  { from = 0, to = str.length },
  endSymbol = "..."
): string {
  return `${str.substring(from, to)}${endSymbol}`;
}

export function trimCellValue(
  res: string,
  trimLength = DEFAUL_TRIM_LENGTH
): string {
  return res.length > trimLength
    ? trimStr(res, { from: 0, to: trimLength })
    : res || EMPTY_TABLE_VALUE;
}

export const toBase64 = (str: string): string => btoa(str);
