export const icons: string[] = [
  "upload-file-cloud",
  "two-arrows-refresh",
  "configuration-lines",
  "arrow-right",
  "terminal",
  "small-arrow-down",
  "arrow-left",
  "question-mark",
  "close-rounded",
];
