import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "@sentium/material";

import { SpinnerModule } from "../spinner";

import { DataTableComponent } from "./data-table.component";
import {
  ColumnCellDirective,
  ColumnFooterDirective,
  ColumnHeaderDirective,
  MatchRowHeightsDirective,
  StopClickPropagationDirective,
  StylePaginatorDirective,
} from "./directives";

import { LinkedColumnsMapService } from "./services";

const COMPONENTS = [DataTableComponent];

const DIRECTIVES = [
  MatchRowHeightsDirective,
  ColumnCellDirective,
  ColumnHeaderDirective,
  ColumnFooterDirective,
  StopClickPropagationDirective,
  StylePaginatorDirective,
];

const SHARED_MODULES = [SpinnerModule];

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, ...SHARED_MODULES],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [...COMPONENTS, ...DIRECTIVES],
  providers: [LinkedColumnsMapService],
})
export class TableModule {}
