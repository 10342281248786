export enum ActiveInstanceTableStatus {
  ACTIVE = "ACTIVE",
  BUILD = "BUILD",
  DELETED = "DELETED",
  ERROR = "ERROR",
  HARD_REBOOT = "HARD_REBOOT",
  MIGRATING = "MIGRATNG",
  PASSWORD = "PASSWORD",
  PAUSED = "PAUSED",
  REBOOT = "REBOOT",
  REBUILD = "REBUILD",
  RESCUE = "RESCUE",
  RESIZE = "RESIZE",
  REVERT_RESIZE = "REVERT_RESIZE",
  SERVLED = "SERVLED",
  SERVLED_OFFLOADED = "SERVLED_OFFLOADED",
  SHUTOFF = "SHUTOFF",
  SOFT_DELETED = "SOFT_DELETED",
  SUSPENDED = "SUSPENDED",
  UNKNOWN = "UNKNOWN",
  VERIFY_RESIZE = "VERIFY_RESIZE",
}

export interface Link {
  rel: string;
  href: string;
}

export interface Flavor {
  disk: number;
  ephemeral: number;
  original_name: string;
  ram: number;
  swap: number;
  vcpus: number;
}

export interface DefaultAddresses {
  version: number;
  addr: string;
}

export interface Addresses {
  default: DefaultAddresses[];
}

export interface Link2 {
  rel: string;
  href: string;
}

export interface OsExtendedVolumesVolumesAttached {
  id: string;
}

export interface SecurityGroup {
  name: string;
}

export interface ActiveInstanceTableAPI {
  id: string;
  name: string;
  status: ActiveInstanceTableStatus;
  tenant_id: string;
  user_id: string;
  metadata: object;
  hostId: string;
  image: string;
  flavor: Flavor;
  created: string;
  updated: string;
  addresses: Addresses;
  accessIPv4: string;
  accessIPv6: string;
  progress: number;
  config_drive: string;
  key_name: string;
  security_groups: SecurityGroup[];
}
