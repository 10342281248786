import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

import {
  HeaderModule,
  NotFoundModule,
  SpinnerModule,
} from "@sentium/shared-components";
import { AppRoutingModule } from "./app-routing.module";

import { ROOT_IMPORTS_THIRD_PARTY, ROOT_PROVIDERS } from "./app-configuration";

import { AppComponent } from "./app.component";
import { BaseStepperContentLogicDirective } from "./modules/base/base-stepper-content-logic.directive";

const SHARED_MODULES = [HeaderModule, NotFoundModule, SpinnerModule];

const DIRECTIVES = [BaseStepperContentLogicDirective];

@NgModule({
  declarations: [AppComponent, ...DIRECTIVES],
  imports: [
    // angular
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    // shared-components
    ...SHARED_MODULES,
    ...ROOT_IMPORTS_THIRD_PARTY,
  ],
  providers: [...ROOT_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
