<label class="checkbox-wrapper">
  <input
    type="checkbox"
    class="checkbox-input"
    [checked]="checked"
    (change)="change($event)"
    [formControl]="valueControl"
  />
  <ng-content></ng-content>
  <span class="checkmark"></span>
</label>
