/* eslint-disable @typescript-eslint/no-explicit-any */
export function filterArrayByKey<T extends Record<keyof T, any>>(
  arr: T[],
  search: string,
  key: keyof T
): T[] {
  const filterValue = search.toLowerCase();
  return arr.filter((option) =>
    option[key].toLowerCase().includes(filterValue)
  );
}

export function filterArrayByKeys<T extends Record<keyof T, any>>(
  arr: T[],
  search: string,
  keys: (keyof T)[]
): T[] {
  const filterValue = search.toLowerCase();
  return arr.filter((option) =>
    keys.some((v) => option[v].toLowerCase().includes(filterValue))
  );
}
