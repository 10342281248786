import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, ValidatorFn } from "@angular/forms";

import { EMPTY_LINE } from "@sentium/constants";

import { filter, map, pairwise, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { isNullOrUndefined } from "../common";

@Directive()
export class BaseFieldDirective
  implements OnDestroy, OnInit, ControlValueAccessor
{
  protected destroy$ = new Subject<void>();
  valueControl: FormControl = new FormControl(EMPTY_LINE);

  validators: ValidatorFn[] = [];

  propagateChange = (_: unknown) => {
    // This is intentional. Required for default initialization
  };
  propagateBlur = (_: unknown) => {
    // This is intentional. Required for default initialization
  };

  constructor(validators: Array<any> = []) {
    this.valueControl.setValidators(validators);
    this.validators = validators;
  }

  ngOnInit() {
    this.valueControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        pairwise(),
        filter(([oldValue, newValue]) => oldValue !== newValue),
        map(([_, newValue]) => newValue)
      )
      .subscribe((newValue) => this.propagateChange(newValue));
  }

  writeValue(value: any): void {
    if (!isNullOrUndefined(value)) {
      this.valueControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.valueControl.disable() : this.valueControl.enable();
  }

  onBlurEvent() {
    this.propagateBlur(this.valueControl.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
