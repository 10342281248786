<div class="row">
  <div class="col-6">
    <sentium-available-metadata
      [availableMetadata]="metadataContainerService.availableMetadata"
    ></sentium-available-metadata>
  </div>

  <div class="col-6 d-flex flex-column">
    <sentium-existing-metadata
      [existingMetadata]="metadataContainerService.existingMetadata"
      (existingMetadataEvent)="existingMetadataEvent($event)"
      (existingMetadataInvalidWeight)="existingMetadataInvalidWeight($event)"
    ></sentium-existing-metadata>
  </div>
</div>
