export enum CodeEditorMode {
  CSS = "css",
  HTTP = "http",
  JAVASCRIPT = "javascript",
  MARKDOWN = "markdown",
  SASS = "sass",
  SHELL = "shell",
  SQL = "sql",
  XML = "xml",
  YAML = "yaml",
  JSON = "application/ld+json",
}
