<ng-container
  *ngIf="withSuffixIcon; then calendarWithSuffix; else calendarWithoutSuffix"
></ng-container>

<ng-template #calendarWithSuffix>
  <mat-form-field
    [appearance]="appearance"
    [ngClass]="{ 'full-width': widthFull }"
  >
    <mat-label>{{
      label ? label : ("calendar.default.label" | translate)
    }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      [matDatepickerFilter]="filterDate"
      [formControl]="dateControl"
      (dateInput)="dateInput($event.value)"
    />
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker>
      <mat-date-range-picker-actions *ngIf="customBottomSection">
        <ng-content select="[datepickerBottomSection]"></ng-content>
      </mat-date-range-picker-actions>
    </mat-datepicker>
  </mat-form-field>
</ng-template>

<ng-template #calendarWithoutSuffix>
  <mat-form-field
    [appearance]="appearance"
    [ngClass]="{ 'full-width': widthFull }"
  >
    <mat-label>{{
      label ? label : ("calendar.default.label" | translate)
    }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      [matDatepickerFilter]="filterDate"
      [formControl]="dateControl"
      (dateInput)="dateInput($event.value)"
      (click)="picker.open()"
    />
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-datepicker #picker>
      <mat-date-range-picker-actions *ngIf="customBottomSection">
        <ng-content select="[datepickerBottomSection]"></ng-content>
      </mat-date-range-picker-actions>
    </mat-datepicker>
  </mat-form-field>
</ng-template>
