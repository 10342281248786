import { DOCUMENT } from "@angular/common";
import { APP_INITIALIZER, Provider } from "@angular/core";
import { Router, ActivatedRoute, RouteReuseStrategy } from "@angular/router";
import { HttpBackend, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_RIPPLE_GLOBAL_OPTIONS } from "@angular/material/core";
import { MaterialModule } from "@sentium/material";

import { Store, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { generalReducer } from "@sentium/store";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";

import {
  I18nService,
  MissingTranslationService,
  TitleService,
  TokenInterceptor,
  IconsService,
  DefaultHeadersInterceptor,
  TokenFacadeService,
} from "@sentium/services";
import {
  createTranslateLoader,
  initCustomSvgIconsisnitLocalization,
  initLocalization,
  initStepper,
  initTitleChange,
  initToken,
  SentiumRouteReuseStrategy,
  storageFactory,
} from "@sentium/common-utils";
import {
  STORAGE_LANGUAGE_KEY,
  STORAGE_LANGUAGE_VALUE,
  SITE_NAME,
  SITE_NAME_VALUE,
  STORAGE_TOKEN,
  ENV_TOKEN,
} from "@sentium/constants";

import { environment } from "../environments/environment";

const TRANSLATE_IMPORT = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpBackend],
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationService,
    },
  }),
];

const STORE = [
  StoreModule.forRoot(
    { general: generalReducer },
    {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateSerializability: true,
      },
    }
  ),
  EffectsModule.forRoot([]),
  environment.production ? [] : StoreDevtoolsModule.instrument(),
];

export const ROOT_IMPORTS_THIRD_PARTY = [
  MaterialModule,
  ...TRANSLATE_IMPORT,
  ...STORE,
];

const TOKENS: Provider[] = [
  {
    provide: ENV_TOKEN,
    useValue: environment,
  },
  {
    provide: STORAGE_LANGUAGE_KEY,
    useValue: STORAGE_LANGUAGE_VALUE,
  },
  {
    provide: SITE_NAME,
    useValue: SITE_NAME_VALUE,
  },
  {
    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
    useValue: { disabled: true },
  },
  {
    provide: STORAGE_TOKEN,
    useFactory: storageFactory,
    deps: [DOCUMENT],
  },
];

const APP_INITIALIZERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initLocalization,
    deps: [I18nService, TranslateService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initTitleChange,
    deps: [TitleService, Router, ActivatedRoute, SITE_NAME],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initCustomSvgIconsisnitLocalization,
    deps: [IconsService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initStepper,
    deps: [Store],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initToken,
    deps: [TokenFacadeService],
    multi: true,
  },
];

const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DefaultHeadersInterceptor,
    multi: true,
  },
];

const ROUTER = [
  {
    provide: RouteReuseStrategy,
    useClass: SentiumRouteReuseStrategy,
    deps: [],
  },
];

export const ROOT_PROVIDERS = [
  ...TOKENS,
  ...APP_INITIALIZERS,
  ...INTERCEPTORS,
  ...ROUTER,
];
