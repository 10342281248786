import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { DEFAULT_DEBOUNCE_TIME } from "@sentium/constants";

import { BehaviorSubject, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from "rxjs/operators";

import * as types from "../form.types";

import * as moment from "moment";

@Component({
  selector: "sentium-range-calendar",
  templateUrl: "./range-calendar.component.html",
  styleUrls: ["./range-calendar.component.scss"],
})
export class RangeCalendarComponent<T extends moment.Moment>
  implements OnDestroy
{
  private readonly destroy$ = new Subject<void>();
  private readonly dateInput$ = new BehaviorSubject<{
    start: T | null;
    end: T | null;
  }>({ start: null, end: null });

  @Input() label!: string;
  @Input() hint!: string;

  @Input() startPlaceholder!: string;
  @Input() endPlaceholder!: string;

  @Input() minDate!: T;
  @Input() maxDate!: T;

  @Input() appearance: MatFormFieldAppearance = "fill";

  @Input() widthFull = true;
  @Input() disableWeekend = false;
  @Input() customRangeBottomSection = false;

  @Input() set selectedDates(range: { start: T | null; end: T | null }) {
    const { start, end } = { ...range };
    this.range.setValue({ start, end }, { emitEvent: true });
  }

  @Output() dateChangeEvent = new EventEmitter<{
    start: T | null;
    end: T | null;
  }>();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  } as types.RangeCalendarFormControls<T>) as types.RangeCalendarFormGroup<T>;

  constructor() {
    this.initSubscriptions();
  }

  dateStartInput(start: T | null): void {
    this.dateInput$.next({ ...this.dateInput$.value, start });
  }

  dateEndInput(end: T | null): void {
    this.dateInput$.next({ ...this.dateInput$.value, end });
  }

  private initSubscriptions(): void {
    this.range.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((range: { start: T; end: T }) => this.dateInput$.next(range));

    this.dateInput$
      .pipe(
        takeUntil(this.destroy$),
        filter((range: { start: T | null; end: T | null }) => {
          const { start, end } = { ...range };
          return Boolean(start) && Boolean(end);
        }),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe((range: { start: T | null; end: T | null }) => {
        const { start, end } = { ...range };
        this.dateChangeEvent.next({ start, end });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
