import { AbstractControl, ValidatorFn } from "@angular/forms";
import { isIPV4Address, isIPV6Address } from "../ip-validation";

export function ip4Validator(): ValidatorFn {
  return (control: AbstractControl): { invalidFormat: boolean } | null => {
    const input = control.value as string;
    return isIPV4Address(input) ? null : { invalidFormat: true };
  };
}

export function ip6Validator(): ValidatorFn {
  return (control: AbstractControl): { invalidFormat: boolean } | null => {
    const input = control.value as string;
    return isIPV6Address(input) ? null : { invalidFormat: true };
  };
}
