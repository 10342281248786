<div class="radios">
  <ng-container *ngFor="let item of items">
    <label class="radio">
      <input
        type="radio"
        [checked]="item.checked"
        name="radio"
        (click)="clicked(item)"
      />
      <span class="radio-checkmark"></span>
      {{ item.name }}
    </label>
  </ng-container>
</div>
