import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "@sentium/material";

import { IconsModule } from "../icons/icons.module";

import { HeaderComponent } from "./header.component";

const COMPONENTS = [HeaderComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MaterialModule,
    IconsModule,
    TranslateModule.forChild(),
  ],
  exports: [...COMPONENTS],
})
export class HeaderModule {}
