import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CodemirrorModule } from "@ctrl/ngx-codemirror";

import { CodeEditorComponent } from "./code-editor/code-editor.component";
import { CodeEditorOptions } from "./model";
import { DEFAULT_CONFIG, DEFAULT_EDITOR_CONFIG } from "./tokens";

const COMPONENTS = [CodeEditorComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CodemirrorModule],
  providers: [
    {
      provide: DEFAULT_EDITOR_CONFIG,
      useValue: DEFAULT_CONFIG,
    },
  ],
  exports: [...COMPONENTS],
})
export class CodeEditorModule {
  static configEditor(
    cfg: Partial<CodeEditorOptions>
  ): ModuleWithProviders<CodeEditorModule> {
    // default config will be overridden with your props
    const config: Partial<CodeEditorOptions> = { ...DEFAULT_CONFIG, ...cfg };
    return {
      ngModule: CodeEditorModule,
      providers: [
        {
          provide: DEFAULT_EDITOR_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
