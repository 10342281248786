<mat-form-field [appearance]="appearance">
  <mat-label>{{
    label ? label : ("calendar.range.label" | translate)
  }}</mat-label>
  <mat-date-range-input
    [formGroup]="range"
    [rangePicker]="picker"
    [min]="minDate"
    [max]="maxDate"
  >
    <input
      matStartDate
      formControlName="start"
      placeholder="{{
        (startPlaceholder
          ? startPlaceholder
          : 'calendar.range.start-placeholder'
        ) | translate
      }}"
      (dateInput)="dateStartInput($event.value)"
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="{{
        (endPlaceholder ? endPlaceholder : 'calendar.range.end-placeholder')
          | translate
      }}"
      (dateInput)="dateEndInput($event.value)"
    />
  </mat-date-range-input>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions *ngIf="customRangeBottomSection">
      <ng-content select="[datepickerRangeBottomSection]"></ng-content>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
