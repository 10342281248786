import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  I18nService,
  IconsService,
  LocalStorageService,
  TitleService,
  TokenFacadeService,
} from "@sentium/services";
import { GeneralState } from "@sentium/models";
import { setInitStepper } from "@sentium/store";

export function initLocalization(i18nService: I18nService): () => void {
  return () => i18nService.initLocalization();
}

export function initTitleChange(titleService: TitleService): () => void {
  return () => titleService.initRouterEventsSubscription();
}

export function storageFactory(document: Document) {
  return new LocalStorageService(document);
}

export function currentLocale(lang: TranslateService): string {
  return lang.currentLang;
}

export function initCustomSvgIconsisnitLocalization(
  iconsService: IconsService
): () => void {
  return () => iconsService.addCustomSvgIcons();
}

export function initToken(tokenFacadeService: TokenFacadeService): () => void {
  return () => tokenFacadeService.getToken();
}

export function initStepper(store: Store<GeneralState>): () => void {
  return () => store.dispatch(setInitStepper());
}
