<ngx-codemirror
  [ngClass]="{
    'invalid-content-border': invalid,
    'valid-content-border': !invalid
  }"
  #codemirror
  [options]="options"
  [(ngModel)]="editorContent"
  (ngModelChange)="editorContentChange($event)"
>
</ngx-codemirror>
