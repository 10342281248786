import { ImageTabTable, SnapshotTabTable, BootableTabTable } from "../api";

export enum ChooseMachineImageTabName {
  IMAGE = "CHOOSE_MACHINE_IMAGE_TAB",
  SNAPSHOT = "CHOOSE_MACHINE_SNAPSHOT_TAB",
  VOLUME = "CHOOSE_MACHINE_VOLUME_TAB",
}

export interface ChooseMachineImageState {
  imageTab: {
    selected: ImageTabTable[];
    init: ImageTabTable[];
    values: ImageTabTable[];
    isLoading: boolean;
  };
  snapshotTabTab: {
    selected: SnapshotTabTable[];
    init: SnapshotTabTable[];
    values: SnapshotTabTable[];
    isLoading: boolean;
  };
  bootableVolumeTab: {
    selected: BootableTabTable[];
    init: BootableTabTable[];
    values: BootableTabTable[];
    isLoading: boolean;
  };
}
