import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  BaseFieldDirective,
  ngValueAccessorProvide,
} from "@sentium/common-utils";

@Component({
  selector: "sentium-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvide(SwitchComponent)],
})
export class SwitchComponent extends BaseFieldDirective {
  private _selected = false;
  private _blocked = false;

  @Input() set blocked(v: boolean) {
    this._blocked = v;
    if (!v) {
      this.valueControl.setValue(true, { emitEvent: true });
    }
  }
  get blocked(): boolean {
    return this._blocked;
  }

  @Input() set selected(v: boolean) {
    this._selected = v;
    this.valueControl.setValue(v, { emitEvent: true });
  }
  get selected(): boolean {
    return this._selected;
  }

  @Output() changeEvent = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  change(e: Event): void {
    if (this.blocked) {
      if (this.selected) {
        this.selected = true;
        this.valueControl.setValue(true);
      }
      if (!this.selected) {
        this.selected = false;
        this.valueControl.setValue(false);
      }
    }
    if (!this.blocked) {
      this.changeEvent.emit((e.target as HTMLInputElement).checked);
    }
  }
}
