import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BaseFieldDirective,
  ngValueAccessorProvide,
} from "@sentium/common-utils";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "sentium-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvide(CheckboxComponent)],
})
export class CheckboxComponent extends BaseFieldDirective implements OnDestroy {
  protected override destroy$: Subject<void> = new Subject<void>();

  @Input() checked = false;

  @Input() disabled = false;

  @Output() checkedEvent = new EventEmitter<boolean>();

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
    this.initSubscriptions();
  }

  change(e: Event): void {
    this.checkedEvent.emit((e.target as HTMLInputElement).checked);
  }

  private initSubscriptions(): void {
    this.valueControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((v: boolean) => {
        this.checkedEvent.emit(v);
        this.cdr.detectChanges();
      });
  }

  override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }
}
