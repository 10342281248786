/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  CdkDragEnter,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
} from "@angular/cdk/drag-drop";

import { EMPTY_LINE } from "@sentium/constants";

import { cloneDeep } from "lodash";

@Component({
  selector: "sentium-drag-n-drop-grid",
  templateUrl: "./drag-n-drop-grid.component.html",
  styleUrls: ["./drag-n-drop-grid.component.scss"],
})
export class DragNDropGridComponent<T> implements AfterViewInit {
  private _dragItems: T[] = [];

  @Input() gridColCount = 3;
  @Input() gridRowCount = 2;

  @Input() dragBlockClasses!: string[];

  get templateColumns(): string {
    return `repeat(${this.gridColCount}, 1fr)`;
  }

  get templateRows(): string {
    return `repeat(${this.gridRowCount}, 1fr)`;
  }

  @Input() set dragItems(items: T[]) {
    this._dragItems = cloneDeep(items);
  }

  get dragItems(): T[] {
    return this._dragItems;
  }

  @Output() dragItemsEvent = new EventEmitter<T[]>();

  @ContentChild(TemplateRef) template!: TemplateRef<any>;

  @ViewChild(CdkDropListGroup) listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder!: CdkDropList;

  target: CdkDropList | null = null;
  targetIndex!: number;
  source: CdkDropList | null = null;
  sourceIndex!: number;
  activeContainer!: any;

  ngAfterViewInit(): void {
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = "none";
    (phElement.parentElement as HTMLElement).removeChild(phElement);
  }

  itemTrackBy(_: number, v: T): T {
    return v;
  }

  dropListDropped(): void {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = "none";

    (parent as HTMLElement).removeChild(phElement);
    (parent as HTMLElement).appendChild(phElement);
    (parent as HTMLElement).insertBefore(
      (this.source as CdkDropList<any>).element.nativeElement,
      (parent as HTMLElement).children[this.sourceIndex]
    );

    this.target = null;
    this.source = null;
    this.activeContainer = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.dragItems, this.sourceIndex, this.targetIndex);
    }
  }

  cdkDropListEntered(e: CdkDragEnter): void {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf<HTMLCollection, HTMLCollection | HTMLElement>(
      (dropElement.parentElement as HTMLElement).children,
      this.source ? phElement : sourceElement
    );
    const dropIndex = __indexOf<HTMLCollection, HTMLCollection | HTMLElement>(
      (dropElement.parentElement as HTMLElement).children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = `${dropElement.clientWidth}px`;
      /**
       * SKIP HEIGH CHANGES
       * phElement.style.height = `${dropElement.clientHeight}px`;
       */
      (sourceElement.parentElement as HTMLElement).removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = EMPTY_LINE;
    (dropElement.parentElement as HTMLElement).insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    requestAnimationFrame(() => {
      this.placeholder._dropListRef.enter(
        drag._dragRef,
        drag.element.nativeElement.offsetLeft,
        drag.element.nativeElement.offsetTop
      );
    });
  }
}

function __indexOf<T, N>(collection: T, node: N) {
  return Array.prototype.indexOf.call(collection, node);
}
