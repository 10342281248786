import { HttpBackend, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(http),
    "./assets/i18n/",
    ".json"
  );
}
