<mat-form-field appearance="outline">
  <input
    readonly
    matInput
    type="text"
    [placeholder]="placeholder"
    [formControl]="valueControl"
    (click)="menuTrigger.openMenu()"
  />
  <button
    matSuffix
    mat-icon-button
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
</mat-form-field>

<mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
  <ng-container *ngFor="let item of items">
    <button
      [ngClass]="{ selected: item?.selected }"
      mat-menu-item
      (click)="preventDefaultBehaviour($event)"
      (click)="selection(item)"
      class="select-button"
    >
      {{ item.value }}
      <mat-icon class="checked">done</mat-icon>
    </button>
  </ng-container>
</mat-menu>
