import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { MyInterceptorFlag } from "../http.model";
import { HeaderName } from "./header-name";

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipRules = [
      request.url.startsWith("./assets/"),
      Boolean(
        request.params.get(MyInterceptorFlag.SKIP_DEFAULT_HEADERS_ASSIGN)
      ),
    ];

    return skipRules.some((f) => f)
      ? next.handle(request)
      : next.handle(
          request.clone({
            headers: request.headers
              .append(HeaderName.ACCESS_CONTROL_ALLOW_ORIGIN, "*")
              .append(HeaderName.ACCESS_CONTROL_ALLOW_HEADERS, "*")
              .append(HeaderName.CONTENT_TYPE, "application/json")
              .append(HeaderName.ACCEPT, "application/json")
              .append(HeaderName.OPEN_STACK_NOVA_API, "2.83")
              .append(HeaderName.OPENSTACK_API_VERSION, "volume 3.60"),
          })
        );
  }
}
