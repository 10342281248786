import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@sentium/material";

import { MultiSelectMenuComponent } from "./multi-select-menu/multi-select-menu.component";

const COMPONENTS = [MultiSelectMenuComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [...COMPONENTS],
})
export class MultiSelectionMenuModule {}
