<div
  [ngClass]="{
    'loading-tiny': size === 'tiny',
    loading: size === 'normal',
    'loading-huge': size === 'huge'
  }"
>
  <div class="arc"></div>
  <div class="arc"></div>
  <div class="arc"></div>
</div>
