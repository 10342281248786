import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";

import {
  ENGLISH_LNG,
  ENV_TOKEN,
  RoutePath,
  STORAGE_LANGUAGE_KEY,
  STORAGE_TOKEN,
  TEST_LNG,
  UKRAINE_LNG,
} from "@sentium/constants";
import { I18nService } from "@sentium/services";
import { IEnvironment, IStorageContract } from "@sentium/models";

@Component({
  selector: "sentium-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  private readonly TEST_TITLE = "language-title.test";
  private readonly ENG_TITLE = "language-title.english";
  private readonly UNK_TITLE = "language-title.ukrainian";

  readonly mainPath = RoutePath.MAIN;

  displayLanguage!: string;

  readonly userLinks: { name: string }[] = [
    { name: "Link 1" },
    { name: "Link 2" },
    { name: "Link 3" },
  ];

  readonly regionLinks: { name: string }[] = [
    { name: "Link 1" },
    { name: "Link 2" },
    { name: "Link 3" },
  ];

  languagesLinks: { name: string; lng: string }[] = [
    { name: this.ENG_TITLE, lng: ENGLISH_LNG },
    { name: this.UNK_TITLE, lng: UKRAINE_LNG },
  ];

  readonly supportLinks: { name: string }[] = [
    { name: "Link 1" },
    { name: "Link 2" },
    { name: "Link 3" },
  ];

  constructor(
    @Inject(STORAGE_LANGUAGE_KEY) private readonly languageStorageKey: string,
    @Inject(STORAGE_TOKEN) private readonly storage: IStorageContract,
    @Inject(ENV_TOKEN) private readonly env: IEnvironment,
    private readonly i18nService: I18nService,
    private readonly router: Router
  ) {
    this.defineLanguageToSelect();
    this.addTestLanguage();
  }

  /**
   * @description component will be hidden for  main route path
   */
  shouldBeShown = (): boolean => this.router.url !== `/${RoutePath.MAIN}`;

  changeLanguage = (item: { name: string; lng: string }): void => {
    this.i18nService.changeLanguage(item.lng);
    this.defineLanguageToSelect();
  };

  private addTestLanguage(): void {
    this.languagesLinks = this.languagesLinks.concat(
      this.env.production ? [] : [{ name: this.TEST_TITLE, lng: TEST_LNG }]
    );
  }

  private defineLanguageToSelect(): void {
    const language = this.storage.getItem(this.languageStorageKey) as string;
    if (language) {
      this.displayLanguage =
        {
          [ENGLISH_LNG]: this.ENG_TITLE,
          [UKRAINE_LNG]: this.UNK_TITLE,
        }[language] || this.ENG_TITLE;
    }
  }
}
