import { createSelector } from "@ngrx/store";
import { GlobalState, GeneralState } from "@sentium/models";

const generalFeature = (state: GlobalState) => state.general;

export const getGlobalLoading = createSelector(
  generalFeature,
  (state: GeneralState) => state.globalLoading
);

export const getToken = createSelector(
  generalFeature,
  (state: GeneralState) => state.token
);

export const getProjectId = createSelector(
  generalFeature,
  (state: GeneralState) => state.projectId
);

export const getStepper = createSelector(
  generalFeature,
  (state: GeneralState) => state.stepper
);
