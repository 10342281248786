import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class DatatableMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
  }

  override getRangeLabel = (
    _: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `No items to display`;
    }
    length = Math.max(length, 0);
    return `Displaying ${length} items`;
  };
}
