<mat-form-field appearance="outline">
  <mat-select
    [placeholder]="placeholder"
    (selectionChange)="change($event.value)"
    [formControl]="valueControl"
  >
    <mat-option
      *ngFor="let item of items"
      [value]="item.value"
      [disabled]="item?.disabled"
      >{{ item.value }}</mat-option
    >
  </mat-select>
</mat-form-field>
