import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";

import { StepperComponent } from "./stepper.component";

const COMPONENTS = [StepperComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [...COMPONENTS],
})
export class StepperModule {}
