export enum SnapshotTableStatus {
  CREATING = "CREATING",
  AVAILABLE = "AVAILABLE",
  BACKING_UP = "BACKING-UP",
  DELETING = "DELETING",
  ERROR = "ERROR",
  DELETED = "DELETED",
  UNMANAGING = "UNMANAGING",
  RESTORING = "RESTORING",
  ERROR_DELETING = "ERROR_DELETING",
}

export interface SnapshotTabTableAPI {
  created_at: string;
  description: string;
  id: string;
  metadata: unknown;
  name: string;
  size: number;
  status: SnapshotTableStatus;
  updated_at: string;
  volume_id: string;
}
