import { MetadataNode } from "@sentium/models";

import { cloneDeep } from "lodash";

export function reduceNestedByKey(
  accum: MetadataNode[],
  item: MetadataNode,
  matcher: (x: MetadataNode) => boolean
): MetadataNode[] {
  item.children = (item.children || []).reduce(
    (a: MetadataNode[], i: MetadataNode) => reduceNestedByKey(a, i, matcher),
    []
  );
  if (!item.children.length) delete item.children;
  if (matcher(item) || item.children) accum.push(item);
  return accum;
}

const deletetion = (q: string, initial: MetadataNode[], t: MetadataNode) => {
  if (q == t.nodeId) {
    // if the parent node still has some child - don't delete the parent at all, leave it with child
    const foundParentOfNode = findNodeByKey(initial[0], "nodeId", q);
    return !foundParentOfNode || foundParentOfNode.children?.length === 0
      ? []
      : [foundParentOfNode];
  } else {
    return { ...t, children: deleteByParentNodeId(q, initial, t.children) };
  }
};

export const deleteByParentNodeId = (
  q: string,
  initial: MetadataNode[],
  t: MetadataNode[] = []
): MetadataNode[] => t.flatMap((v: MetadataNode) => deletetion(q, initial, v));

export function findNodeByKey(
  tree: MetadataNode,
  key: keyof MetadataNode,
  value: string
): MetadataNode | null {
  let result = null;
  if (tree[key] === value) {
    return tree;
  }
  if (Array.isArray(tree?.children) && tree?.children.length > 0) {
    tree?.children.some((node: MetadataNode) => {
      result = findNodeByKey(node, key, value);
      return result;
    });
  }
  return result;
}

export function restoreDeletedNode(
  tree: MetadataNode,
  parentNode: MetadataNode,
  childNode: MetadataNode
): MetadataNode[] {
  const cloneTree = cloneDeep(tree);
  // in the existing tree, among all the remaining children, I look for a match by id with the parent node that was found from the initial data
  const findParent =
    cloneTree.children &&
    cloneTree.children.find((ch) => ch.nodeId === parentNode.nodeId);
  if (findParent) {
    findParent.children?.push(childNode);
  } else {
    const cloneParent = cloneDeep(parentNode);
    // does the found parent contain
    if (
      cloneParent.children &&
      cloneParent.children.find((ch) => ch.parentNodeId === cloneParent.nodeId)
    ) {
      cloneParent.children = [];
      cloneParent.children.push(childNode);
    } else {
      (cloneParent.children as MetadataNode[]).push(childNode);
    }
    (cloneTree.children as MetadataNode[]).push(cloneParent);
  }
  return [cloneTree];
}
