/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";

import { MetadataNode } from "@sentium/models";

export class TreeDataSource extends MatTreeNestedDataSource<MetadataNode> {
  constructor(
    private treeControl: NestedTreeControl<MetadataNode>,
    intialData: MetadataNode[]
  ) {
    super();
    this.data = intialData;
  }

  /** Add node as child of parent */
  add(node: MetadataNode, parent: MetadataNode) {
    const newTreeData = { name: "", children: this.data };
    this._add(node, parent, newTreeData);
    this.data = newTreeData.children;
  }

  /** Remove node from tree */
  remove(node: MetadataNode) {
    const newTreeData = { name: "", children: this.data };
    this._remove(node, newTreeData);
    this.data = newTreeData.children;
  }

  protected _add(
    newNode: MetadataNode,
    parent: MetadataNode,
    tree: MetadataNode
  ): boolean {
    if (tree === parent) {
      tree.children = [...tree.children!, newNode];
      this.treeControl.expand(tree);
      return true;
    }
    if (!tree.children) {
      return false;
    }
    return this.update(tree, this._add.bind(this, newNode, parent));
  }

  _remove(node: MetadataNode, tree: MetadataNode): boolean {
    if (!tree.children) {
      return false;
    }
    const i = tree.children.indexOf(node);
    if (i > -1) {
      tree.children = [
        ...tree.children.slice(0, i),
        ...tree.children.slice(i + 1),
      ];
      this.treeControl.collapse(node);
      return true;
    }
    return this.update(tree, this._remove.bind(this, node));
  }

  update(tree: MetadataNode, predicate: (n: MetadataNode) => boolean) {
    let updatedTree: MetadataNode, updatedIndex: number;

    tree.children!.find((node, i) => {
      if (predicate(node)) {
        updatedTree = { ...node };
        updatedIndex = i;
        this.moveExpansionState(node, updatedTree);
        return true;
      }
      return false;
    });

    if (updatedTree!) {
      tree.children![updatedIndex!] = updatedTree!;
      return true;
    }
    return false;
  }

  moveExpansionState(from: MetadataNode, to: MetadataNode) {
    if (this.treeControl.isExpanded(from)) {
      this.treeControl.collapse(from);
      this.treeControl.expand(to);
    }
  }
}
