import { Directive } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { GlobalState, IStep } from "@sentium/models";
import { RoutePath } from "@sentium/constants";
import { changeActiveStep, getStepper } from "@sentium/store";
import { getNextPathId } from "@sentium/common-utils";

import { Observable } from "rxjs";

@Directive({
  selector: "[sentiumBaseStepperContentLogic]",
})
export class BaseStepperContentLogicDirective {
  protected nextDisabled!: boolean;

  private readonly _stepper$: Observable<IStep[]> = this.store$.pipe(
    select(getStepper)
  );
  protected get stepper$(): Observable<IStep[]> {
    return this._stepper$;
  }

  constructor(
    protected readonly router: Router,
    protected readonly store$: Store<GlobalState>
  ) {}

  protected next(path: RoutePath): void {
    if (!this.nextDisabled) {
      this.router.navigate([path]);
      const id = getNextPathId(path);
      this.store$.dispatch(changeActiveStep({ id }));
    }
  }

  protected clickItem(item: IStep) {
    const { id, path } = { ...item };
    this.router.navigate([path]);
    this.store$.dispatch(changeActiveStep({ id }));
  }
}
