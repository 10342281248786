import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@sentium/material";
import { SwitchComponent } from "./switch/switch.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { SelectComponent } from "./select/select.component";

const COMPONENTS = [SwitchComponent, CheckboxComponent, SelectComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [...COMPONENTS],
})
export class InputsModule {}
