export enum VolumeTableStatus {
  CREATING = "creating",
  AVAILABLE = "available",
  RESERVED = "reserved",
  ATTACHING = "attaching",
  DETACHING = "detaching",
  IN_USE = "in-use",
  MAINTENANCE = "maintenance",
  DELETING = "deleting",
  AWAITING_TRANSFER = "awaiting-transfer",
  ERROR = "error",
  ERROR_DELETING = "error_deleting",
  BACKING_UP = "backing-up",
  RESTORING_BACKUP = "restoring-backup",
  ERROR_BACKING_UP = "error_backing-up",
  ERROR_RESTORING = "error_restoring",
  ERROR_EXTENDING = "error_extending",
  DOWNLOADING = "downloading",
  UPLOADING = "uploading",
  RETYPING = "retyping",
  EXTENDING = "extending",
}

export interface BootableTabTableImageMetadata {
  checksum: string;
  container_format: string;
  disk_format: string;
  hw_rng_model: string;
  image_id: string;
  image_name: string;
  min_disk: string;
  min_ram: string;
  size: string;
}

export interface BootableTabTableAttachment {
  id: string;
  attachment_id: string;
  volume_id: string;
  server_id: string;
  host_name: string;
  device: string;
  attached_at: string;
}

export interface BootableTabTableAPI {
  attachments: Array<BootableTabTableAttachment>;
  availability_zone: string;
  bootable: string;
  consistencygroup_id: unknown;
  created_at: string;
  description: string;
  encrypted: boolean;
  id: string;
  links: Array<{ href: string; rel: string }>;
  metadata: unknown;
  multiattach: boolean;
  name: string;
  replication_status: unknown;
  size: number;
  snapshot_id: unknown;
  source_volid: unknown;
  status: VolumeTableStatus;
  updated_at: string;
  user_id: string;
  volume_image_metadata: BootableTabTableImageMetadata;
  volume_type: string;
}
