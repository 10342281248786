export enum StepId {
  CHOOSE_MACHINE_IMAGE,
  CHOOSE_INSTANCE_TYPE,
  CONFIGURE_INSTANCE,
  ADD_STORAGE,
  NETWORK_SETTINGS,
  REVIEW,
}

export interface IStep {
  id: StepId;
  name: string;
  path: string;
  active: boolean;
}
