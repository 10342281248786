/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[columnFooter]",
})
export class ColumnFooterDirective {
  @Input() columnFooter!: string;

  constructor(public template: TemplateRef<any>) {}
}
