import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BaseFieldDirective,
  ngValueAccessorProvide,
} from "@sentium/common-utils";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "sentium-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvide(SelectComponent)],
})
export class SelectComponent extends BaseFieldDirective implements OnDestroy {
  protected override destroy$: Subject<void> = new Subject<void>();

  @Input() placeholder!: string;
  @Input() items!: { value: string | number; disabled?: boolean }[];

  @Input() set value(v: number | string) {
    this.valueControl.setValue(v, { emitEvent: true });
  }

  @Output() selectedEvent = new EventEmitter<string | number>();

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
    this.initSubscriptions();
  }

  change(e: string | number): void {
    this.selectedEvent.emit(e);
  }

  private initSubscriptions(): void {
    this.valueControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((v: string | number) => {
        this.selectedEvent.emit(v);
        this.cdr.detectChanges();
      });
  }

  override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }
}
