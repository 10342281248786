import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IStep } from "@sentium/models";

import { cloneDeep } from "lodash";

@Component({
  selector: "sentium-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  private _items!: IStep[];

  @Input() set items(v: IStep[] | null) {
    if (!v) {
      return;
    }
    this._items = v.some((f) => !f.active)
      ? cloneDeep(v)
      : this.makeFirstItemActive(v);
  }

  get items(): IStep[] {
    return this._items;
  }

  @Output() itemActiveEvent = new EventEmitter<IStep>();

  private makeFirstItemActive(v: IStep[]) {
    const clone = cloneDeep(v);
    clone[0].active = true;
    return clone;
  }

  setActiveItem(index: number) {
    this._items = this._items.map((item, i) => {
      item.active = i === index;
      return item;
    });
    this.itemActiveEvent.emit(this._items[index]);
  }
}
