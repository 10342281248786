import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "@sentium/material";

import { DragNDropGridComponent } from "./drag-n-drop-grid.component";

const COMPONENTS = [DragNDropGridComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MaterialModule],
  exports: [...COMPONENTS],
})
export class DragNDropGridModule {}
