import {
  HostListener,
  HostBinding,
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  Input,
} from "@angular/core";
import { LinkedColumnsMapService } from "../services";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[matchRowHeights]",
})
export class MatchRowHeightsDirective implements AfterViewInit, OnDestroy {
  private rows: HTMLElement[] = [];

  @Input() matchRowHeights!: string;

  @HostBinding("class.sub-table") subTable = true;

  @HostListener("window:resize")
  private resize(): void {
    this.resizeRows();
  }

  constructor(
    public el: ElementRef,
    private linkedColumns: LinkedColumnsMapService
  ) {}

  ngAfterViewInit(): void {
    this.linkedColumns.add(this.matchRowHeights, this);
    this.rows = Array.from(this.el.nativeElement.querySelectorAll("div"));
    this.resizeRows();
  }

  ngOnDestroy(): void {
    this.linkedColumns.remove(this.matchRowHeights, this);
  }

  resizeRows(): void {
    const allDirs = this.linkedColumns.get(this.matchRowHeights);
    for (let i = 0; i < this.rows.length; ++i) {
      const linkedRows = allDirs.map((d) => d.rows[i]);
      linkedRows.forEach((r) => (r.style.flexBasis = "initial"));

      const maxHeight = Math.max(...linkedRows.map((r) => r.offsetHeight));
      linkedRows.forEach((r) => (r.style.flexBasis = maxHeight + "px"));
    }
  }
}
