import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressBlockComponent } from "./progress-block/progress-block.component";

const COMPONENS = [ProgressBlockComponent];

@NgModule({
  declarations: [...COMPONENS],
  imports: [CommonModule],
  exports: [...COMPONENS],
})
export class ProgressModule {}
