import { InjectionToken } from "@angular/core";
import { Action, ActionReducerMap, StoreModule } from "@ngrx/store";
import { GlobalState } from "@sentium/models";

export function addFeatureReducer(
  key: keyof GlobalState,
  reducer:
    | ActionReducerMap<unknown, Action>
    | InjectionToken<ActionReducerMap<unknown, Action>>
) {
  return StoreModule.forFeature(key, reducer);
}
