<ng-container
  *ngIf="isGlobalLoading$ | async; then loadingTemplate; else contentTemplate"
></ng-container>

<ng-template #loadingTemplate>
  <div class="global-spinner-wrapper">
    <sentium-spinner [size]="'huge'"></sentium-spinner>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <sentium-header></sentium-header>

  <router-outlet></router-outlet>
</ng-template>
