import { Data, Route } from "@angular/router";

enum RoutePath {
  MAIN = "main",
  DASHBOARD = "dashboard",
  VM_DASHBOARD = "vm-dashboard",
  CHOOSE_MACHINE_IMAGE = "choose-machine-image",
  NETWORK_SETTINGS = "network-settings",
  CHOOSE_INSTANCE_TYPE = "choose-instance-type",
  CONFIGURE_INSTANCE = "configure-instance",
  REVIEW = "review",
  ADD_STORAGE = "add-storage",
  UNKNOWN = "",
}

interface AdditionalRouteData {
  // used to display as browser page title
  title: string;
  reuse?: {
    id?: string;
    disable?: boolean;
  };
}

type RouteData = Data & AdditionalRouteData;
type SentiumRouteRoutes = SentiumRoute[];
type SentiumRoute = Route & {
  data?: RouteData;
  children?: SentiumRouteRoutes;
};

export {
  RoutePath,
  AdditionalRouteData,
  RouteData,
  SentiumRouteRoutes,
  SentiumRoute,
};
