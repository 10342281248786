import { Component, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { changeActiveStep, getGlobalLoading } from "@sentium/store";
import { GlobalState } from "@sentium/models";
import { getStepId } from "@sentium/common-utils";
import { RoutePath } from "@sentium/constants";

import { filter, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "sentium-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  isGlobalLoading$: Observable<boolean> = this.store$.select(getGlobalLoading);

  constructor(
    private readonly store$: Store<GlobalState>,
    private readonly router: Router
  ) {
    this.subscribeToNavigationToCheckActiveStepper();
  }

  private subscribeToNavigationToCheckActiveStepper(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        filter((event) => {
          const url = (event as { url: string }).url;
          return (
            url === `/${RoutePath.CHOOSE_MACHINE_IMAGE}` ||
            url === `/${RoutePath.CHOOSE_INSTANCE_TYPE}` ||
            url === `/${RoutePath.CONFIGURE_INSTANCE}` ||
            url === `/${RoutePath.ADD_STORAGE}` ||
            url === `/${RoutePath.NETWORK_SETTINGS}` ||
            url === `/${RoutePath.REVIEW}`
          );
        })
      )
      .subscribe((event) => {
        const url = (event as { url: string }).url;
        this.store$.dispatch(changeActiveStep({ id: getStepId(url) }));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
