import { Component, Input, OnDestroy, SkipSelf } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

import { NestedTreeControl } from "@angular/cdk/tree";

import { DEFAULT_DEBOUNCE_TIME, EMPTY_LINE } from "@sentium/constants";
import { MetadataNode } from "@sentium/models";

import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";
import { cloneDeep } from "lodash";

import { MetadataContainerService } from "../metadata-container.service";
import { TreeDataSource } from "../tree-data-source";
import { deleteByParentNodeId, reduceNestedByKey } from "../util";

@Component({
  selector: "sentium-available-metadata",
  templateUrl: "./available-metadata.component.html",
  styleUrls: ["./available-metadata.component.scss"],
})
export class AvailableMetadataComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private _initialValues!: MetadataNode[];

  treeControl = new NestedTreeControl<MetadataNode>((node) => node.children);
  dataSource!: TreeDataSource;

  customMeta = new FormControl(EMPTY_LINE, [Validators.required]);

  filter = new FormControl(EMPTY_LINE);

  @Input() set availableMetadata(v: MetadataNode[] | null) {
    if (!v) {
      return;
    }
    this._initialValues = cloneDeep(v);
    this.dataSource = new TreeDataSource(this.treeControl, v);
  }

  constructor(
    @SkipSelf()
    private readonly metadataContainerService: MetadataContainerService
  ) {
    this.searchSubscription();
  }

  hasChild = (_: number, node: MetadataNode) =>
    !!node.children && node.children.length > 0;

  addCustomMeta(): void {
    if (this.customMeta.valid) {
      const meta: { key: string; takenFromAvailable: boolean } = {
        key: this.customMeta.value,
        takenFromAvailable: false,
      };
      this.metadataContainerService.customMetadata = meta;
      this.customMeta.reset();
    }
  }

  addMeta(node: MetadataNode) {
    this.dataSource.remove(node);
    this.treeFilter(node);
    this.metadataContainerService.availableMetadata = this.dataSource.data;
    const meta: { key: string; takenFromAvailable: boolean } = {
      key: node.name,
      takenFromAvailable: true,
    };
    this.metadataContainerService.customMetadata = meta;
  }

  private searchSubscription(): void {
    this.filter.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(EMPTY_LINE),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe((v: string) =>
        v === EMPTY_LINE ? this.initialTree() : this.treeSearch(v)
      );
  }

  private readonly initialTree = (): void => {
    this.dataSource = new TreeDataSource(this.treeControl, this._initialValues);
    this.dataSource.update(
      { name: EMPTY_LINE, children: this._initialValues },
      () => true
    );
  };

  private readonly treeSearch = (v: string) => {
    const newTreeData: MetadataNode[] = this.dataSource.data.reduce<
      MetadataNode[]
    >(
      (a, i) =>
        reduceNestedByKey(a, i, (x: MetadataNode) =>
          x.name.toLowerCase().includes(v.toLowerCase())
        ),
      []
    );
    this.dataSource = new TreeDataSource(this.treeControl, newTreeData);
    this.dataSource.update(
      { name: EMPTY_LINE, children: newTreeData },
      () => true
    );
  };

  private readonly treeFilter = (node: MetadataNode) => {
    const newTreeData = deleteByParentNodeId(
      node.parentNodeId as string,
      this.dataSource.data,
      this.dataSource.data
    );
    this.dataSource = new TreeDataSource(this.treeControl, newTreeData);
    this.dataSource.update(
      { name: EMPTY_LINE, children: newTreeData },
      () => true
    );
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
