import { createReducer, Action, on } from "@ngrx/store";
import { GeneralState } from "@sentium/models";
import {
  changeActiveStep,
  generalIsLoading,
  setInitStepper,
  setProjectId,
  setToken,
} from "./actions";

import { cloneDeep } from "lodash";
import { DEFAULT_STEPPER } from "./defaults";

export const GENERAL_INITIAL_STATE: GeneralState = {
  globalLoading: true,
  token: "",
  projectId: "",
  stepper: [],
};

const featureReducer = createReducer(
  GENERAL_INITIAL_STATE,
  on(generalIsLoading, (state, { flag }) => ({
    ...state,
    globalLoading: flag,
  })),
  on(setToken, (state, { token }) => ({
    ...state,
    token,
  })),
  on(setProjectId, (state, { id }) => ({
    ...state,
    projectId: id,
  })),
  on(setInitStepper, (state) => ({
    ...state,
    stepper: cloneDeep(DEFAULT_STEPPER),
  })),
  on(changeActiveStep, (state, { id }) => {
    const cloneState = cloneDeep(state);
    cloneState.stepper.forEach((step) => (step.active = step.id === id));
    return {
      ...cloneState,
    };
  })
);

export function generalReducer(
  state: GeneralState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
