import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RadiosGroupComponent } from "./radios-group/radios-group.component";

const COMPONENTS = [RadiosGroupComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule],
  exports: [...COMPONENTS],
})
export class RadiosModule {}
