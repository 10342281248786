import { RoutePath } from "@sentium/constants";
import { StepId } from "@sentium/models";

export const getStepId = (url: string) => {
  return {
    [`/${[RoutePath.CHOOSE_MACHINE_IMAGE]}`]: StepId.CHOOSE_MACHINE_IMAGE,
    [`/${[RoutePath.CHOOSE_INSTANCE_TYPE]}`]: StepId.CHOOSE_INSTANCE_TYPE,
    [`/${[RoutePath.CONFIGURE_INSTANCE]}`]: StepId.CONFIGURE_INSTANCE,
    [`/${[RoutePath.ADD_STORAGE]}`]: StepId.ADD_STORAGE,
    [`/${[RoutePath.NETWORK_SETTINGS]}`]: StepId.NETWORK_SETTINGS,
    [`/${[RoutePath.REVIEW]}`]: StepId.REVIEW,
  }[url];
};
