const ALL_TAB_ID = 0;
const GENERAL_TAB_ID = 1;
const COMPUTE_TAB_ID = 2;

const IMAGE_TAB_ID = 0;
const SNAPSHOT_TAB_ID = 1;
const VOLUME_TAB_ID = 2;

export {
  ALL_TAB_ID,
  GENERAL_TAB_ID,
  COMPUTE_TAB_ID,
  IMAGE_TAB_ID,
  SNAPSHOT_TAB_ID,
  VOLUME_TAB_ID,
};
