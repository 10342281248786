import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
} from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTable } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ColumnDef, DataSource } from "@sentium/models";

import { cloneDeep, isEqual } from "lodash";

import {
  ColumnCellDirective,
  ColumnFooterDirective,
  ColumnHeaderDirective,
} from "./directives";

@Component({
  selector: "sentium-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent<T> implements OnChanges, AfterViewInit {
  private _data!: DataSource<T>;
  private _deselectAll = false;
  private _selectedItems!: T[];

  @ViewChild(MatTable) table!: MatTable<T>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // Paginator config
  @Input() rowsPerPage = [5, 10, 15];
  @Input() pageSize = 5;
  @Input() currentPage = 0;
  @Input() hidePageSize = false;
  @Input() showFirstLastButtonsPaginator = true;

  @Input() isLoading = false;

  @Input() set selectedItems(v: T[] | null) {
    if (!v) {
      return;
    }
    this._selectedItems = cloneDeep(v);
    if (this.dataSource) {
      this.selectSelectedItems();
    }
  }

  get selectedItems(): T[] {
    return this._selectedItems;
  }

  @Input() selectColumn = false;
  @Input() dragable = false;
  @Input() isSelectable = false;
  @Input() singleSelection = false;

  @Input() set deselectAll(v: boolean | null) {
    if (!v) {
      return;
    }
    this._deselectAll = v;
  }
  get deselectAll(): boolean {
    return this._deselectAll;
  }

  @Input() set dataSource(data: DataSource<T> | null) {
    if (!data) {
      return;
    }
    this._data = cloneDeep(data);
    if (this.selectedItems) {
      this.selectSelectedItems();
    }
    this.hidePageSizeCondition();
  }
  get dataSource(): DataSource<T> {
    return this._data;
  }

  @Input() set columnDefs(cols: Partial<ColumnDef<T>>[]) {
    if (!cols) {
      return;
    }
    this.columns = cols.map((c) => ({ ...new ColumnDef<T>(), ...c }));
    this.updateColumns();
  }

  @Output() selectionEvent = new EventEmitter<T[]>();
  @Output() deselectionEvent = new EventEmitter<T[]>();

  columns: ColumnDef<T>[] = [];

  activeColumns: string[] = [];

  selection = new SelectionModel<T>(true, []);

  ngOnChanges(simple: {
    selectColumn: SimpleChange;
    deselectAll: SimpleChange;
    dataSource: SimpleChange;
  }): void {
    if (simple?.selectColumn) {
      this.updateColumns();
    }

    if (simple?.deselectAll && !!simple.deselectAll.currentValue) {
      this.selection.clear();
      this.deselectionEvent.emit([]);
    }

    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.selectionEvent.emit(this.selection.selected);
  }

  selectionRow(): void {
    this.selectionEvent.emit(this.selection.selected);
  }

  updateColumns(): void {
    const definedColumns: string[] = [
      ...this.columns.filter((col) => col.isActive).map((col) => col.key),
    ];
    this.activeColumns = this.selectColumn
      ? ["select", ...definedColumns]
      : [...definedColumns];
  }

  reorderCols(event: CdkDragDrop<ColumnDef<T>[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.updateColumns();
  }

  drop(event: CdkDragDrop<T[]>): void {
    const prevIndex = this.dataSource.data.findIndex(
      (d) => d === event.item.data
    );
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);
    this.dataSource.data = this.dataSource.data.slice();
    this.table.renderRows();
  }

  /**
   * @description if single selection mode turned on - only one item can be selected. Selection will be prevened via input `isSelectable = false`
   */
  itemSelected(row: T): void {
    if (this.isSelectable) {
      const selected = this.selection.selected[0];

      // single selection will deselect previous sleected value and select new
      if (this.singleSelection) {
        // if the same row selected as already was - deselect it
        if (isEqual(selected, row)) {
          this.selection.toggle(row);
          this.selectionEvent.emit(this.selection.selected);
          return;
        }
        // if select is found - clear the state and select the new one
        if (selected) {
          this.selection.clear();
          this.selection.toggle(row);
        } else {
          this.selection.toggle(row);
        }
      } else {
        this.selection.toggle(row);
      }
      this.selectionEvent.emit(this.selection.selected);
    }
  }

  private readonly hidePageSizeCondition = () =>
    this.dataSource.data.length <= this.pageSize;

  private selectSelectedItems() {
    this.dataSource.data.forEach((row) => {
      if (this.selectedItems.length === 0) {
        this.selection.clear();
        return;
      }
      this.selectedItems.forEach((item) => {
        if (
          isEqual(item, row) &&
          !this.selection.selected.find((el) => isEqual(el, row))
        ) {
          this.selection.select(row);
        }
      });
    });
  }

  @ContentChildren(ColumnCellDirective)
  set cellTemplates(defs: QueryList<ColumnCellDirective>) {
    defs.forEach((def) => {
      const col = this.columns.find((x) => x.key === def.columnCell) || {
        cellTemplate: null,
        key: null,
      };
      col.cellTemplate = def.template;
      if (!(col as ColumnDef<T>).key) {
        (col as ColumnDef<T>).key = def.columnCell;
        this.columns = [
          ...this.columns,
          {
            ...new ColumnDef(),
            ...(col as ColumnDef<T>),
          },
        ];
      }
    });
  }

  @ContentChildren(ColumnHeaderDirective)
  set headerTemplates(defs: QueryList<ColumnHeaderDirective>) {
    defs.forEach((def) => {
      const col = this.columns.find((x) => x.key === def.columnHeader) || {
        headerTemplate: null,
        field: null,
      };
      col.headerTemplate = def.template;
      if (!(col as ColumnDef<T>).key) {
        (col as ColumnDef<T>).key = def.columnHeader;
        this.columns = [
          ...this.columns,
          {
            ...new ColumnDef(),
            ...(col as ColumnDef<T>),
          },
        ];
      }
    });
  }

  @ContentChildren(ColumnFooterDirective)
  set footerTemplates(defs: QueryList<ColumnFooterDirective>) {
    defs.forEach((def) => {
      const col = this.columns.find((x) => x.key === def.columnFooter) || {
        footerTemplate: null,
        key: null,
      };
      col.footerTemplate = def.template;
      if (!col.key) {
        col.key = def.columnFooter;
        this.columns = [
          ...this.columns,
          {
            ...new ColumnDef(),
            ...(col as ColumnDef<T>),
          },
        ];
      }
    });
  }
}
