import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import {
  ENGLISH_LNG,
  STORAGE_LANGUAGE_KEY,
  STORAGE_TOKEN,
  EMPTY_LINE,
} from "@sentium/constants";
import { IStorageContract } from "@sentium/models";

import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class I18nService {
  private readonly availableLanguages = [ENGLISH_LNG];
  private readonly defaultLang = ENGLISH_LNG;

  constructor(
    private readonly translate: TranslateService,
    @Inject(STORAGE_TOKEN) private readonly storage: IStorageContract,
    @Inject(STORAGE_LANGUAGE_KEY) private readonly languageStorageKey: string
  ) {}

  initLocalization(): void {
    const lang = this.availableLanguages.includes(
      this.toShortLocale(this.translate.getBrowserLang())
    )
      ? this.translate.getBrowserLang()
      : this.defaultLang;

    const storedLanguage = this.storage.getItem(this.languageStorageKey);
    storedLanguage
      ? this.changeLanguage(storedLanguage)
      : this.changeLanguage(lang);
  }

  private toShortLocale(locale: string): string {
    if (!locale || locale == EMPTY_LINE) {
      return this.defaultLang;
    }

    return (
      {
        [ENGLISH_LNG]: ENGLISH_LNG,
      }[locale] || this.defaultLang
    );
  }

  changeLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.storage.setItem(this.languageStorageKey, lang);
    moment.locale(lang);
  }
}
