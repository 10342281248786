import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
} from "@angular/router";
import { RouteData, RoutePath } from "@sentium/constants";
import { StepId } from "@sentium/models";

/**
 * Reuse strategy can be turn on if component's data in routing configs will be added as `data: { reuse: { id: string } }`
 * To disable reuse add `data: { reuse: { disable: true } }`
 */
export class SentiumRouteReuseStrategy extends BaseRouteReuseStrategy {
  /** Gets called every time when we navigate between routes. There are two types future and current.
   * The Future is the route we are leaving and the Current is the route we are landing.
   */
  override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const futureReuseId = (future.data as RouteData)?.reuse?.id;
    const currReuseId = (curr.data as RouteData)?.reuse?.id;
    const disableReuse = !(curr.data as RouteData)?.reuse?.disable;
    // check if reuse is disabled on this route
    return (
      disableReuse &&
      // default is to reuse if the route is the same
      (future.routeConfig === curr.routeConfig ||
        // otherwise reuse if there's a reuse id on both routes
        (Boolean(futureReuseId) &&
          Boolean(currReuseId) &&
          futureReuseId === currReuseId))
    );
  }
}

export function getNextPathId(path: RoutePath): StepId {
  switch (true) {
    case path === RoutePath.CHOOSE_MACHINE_IMAGE:
      return StepId.CHOOSE_INSTANCE_TYPE;
    case path === RoutePath.CHOOSE_INSTANCE_TYPE:
      return StepId.CONFIGURE_INSTANCE;
    case path === RoutePath.CONFIGURE_INSTANCE:
      return StepId.ADD_STORAGE;
    case path === RoutePath.ADD_STORAGE:
      return StepId.NETWORK_SETTINGS;
    case path === RoutePath.NETWORK_SETTINGS:
      return StepId.REVIEW;
    default:
      return StepId.CHOOSE_MACHINE_IMAGE;
  }
}
