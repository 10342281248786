import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "@sentium/material";

import { TabsComponent } from "./tabs.component";

const COMPONENTS = [TabsComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MaterialModule],
  exports: [...COMPONENTS],
})
export class TabsModule {}
