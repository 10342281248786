import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { RoutePath, SentiumRouteRoutes } from "@sentium/constants";
import { NotFoundComponent } from "@sentium/shared-components";

const routes: SentiumRouteRoutes = [
  {
    path: "",
    redirectTo: RoutePath.MAIN,
    pathMatch: "full",
  },
  {
    path: RoutePath.MAIN,
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: RoutePath.DASHBOARD,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    data: {
      title: "Dashboard",
    },
  },
  {
    path: RoutePath.VM_DASHBOARD,
    loadChildren: () =>
      import("./modules/vms-dashboard/vms-dashboard.module").then(
        (m) => m.VmsDashboardModule
      ),
    data: {
      title: "VMs Dashboard",
    },
  },
  {
    path: RoutePath.CHOOSE_MACHINE_IMAGE,
    loadChildren: () =>
      import("./modules/choose-machine-image/choose-machine-image.module").then(
        (m) => m.ChooseMachineImageModule
      ),
    data: {
      title: "Choose Machine Image",
      reuse: {
        id: RoutePath.CHOOSE_MACHINE_IMAGE,
      },
    },
  },
  {
    path: RoutePath.CHOOSE_INSTANCE_TYPE,
    loadChildren: () =>
      import("./modules/choose-instance-type/choose-instance-type.module").then(
        (m) => m.ChooseInstanceTypeModule
      ),
    data: {
      title: "Choose Instance Type",
    },
  },
  {
    path: RoutePath.CONFIGURE_INSTANCE,
    loadChildren: () =>
      import("./modules/configure-instance/configure-instance.module").then(
        (m) => m.ConfigureInstanceModule
      ),
    data: {
      title: "Choose Instance Type",
    },
  },
  {
    path: RoutePath.ADD_STORAGE,
    loadChildren: () =>
      import("./modules/add-storage/add-storage.module").then(
        (m) => m.AddStorageModule
      ),
    data: {
      title: "Add storage Settings",
    },
  },
  {
    path: RoutePath.NETWORK_SETTINGS,
    loadChildren: () =>
      import("./modules/network-settings/network-settings.module").then(
        (m) => m.NetworkSettingsModule
      ),
    data: {
      title: "Network Settings",
    },
  },
  {
    path: RoutePath.REVIEW,
    loadChildren: () =>
      import("./modules/review/review.module").then((m) => m.ReviewModule),
    data: {
      title: "Review",
    },
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
