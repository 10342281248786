import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { progressBarPotentialClass, progressBarUsedClass } from "../util";

@Component({
  selector: "sentium-progress-block",
  templateUrl: "./progress-block.component.html",
  styleUrls: ["./progress-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBlockComponent {
  private _progress!: number;
  private _potential!: number;

  progressClass = progressBarUsedClass;
  potentialClass = progressBarPotentialClass;

  @Input() set progress(v: number | null) {
    this._progress = this.checkValue(v);
  }
  get progress(): number {
    return this._progress;
  }
  get progressPercentage(): string {
    return `${this._progress}%`;
  }

  @Input() set potential(v: number | null) {
    if (!v || v === 0) {
      this._potential = 0;
    }
    this._potential = this.checkValue(v);
  }
  get potential(): number {
    return this._potential;
  }
  get potentialPercentage(): string {
    return `${this._potential}%`;
  }

  @Input() title!: string;
  @Input() infoTitle!: string | null;
  @Input() infoSubTitle!: string;

  private checkValue(v: number | null) {
    if (typeof v === "number") {
      if (v < 0 || !v) {
        return 0;
      }
      if (v > 100) {
        return 100;
      }
    }

    if (v === null) {
      return 0;
    }

    return v;
  }
}
