import { IStep, StepId } from "@sentium/models";
import { RoutePath } from "@sentium/constants";

const getName = (v: string) => `common-phrase.modules.${v}.title`;

export const DEFAULT_STEPPER: IStep[] = [
  {
    id: StepId.CHOOSE_MACHINE_IMAGE,
    name: getName(RoutePath.CHOOSE_MACHINE_IMAGE),
    path: RoutePath.CHOOSE_MACHINE_IMAGE,
    active: true,
  },
  {
    id: StepId.CHOOSE_INSTANCE_TYPE,
    name: getName(RoutePath.CHOOSE_INSTANCE_TYPE),
    path: RoutePath.CHOOSE_INSTANCE_TYPE,
    active: false,
  },
  {
    id: StepId.CONFIGURE_INSTANCE,
    name: getName(RoutePath.CONFIGURE_INSTANCE),
    path: RoutePath.CONFIGURE_INSTANCE,
    active: false,
  },
  {
    id: StepId.ADD_STORAGE,
    name: getName(RoutePath.ADD_STORAGE),
    path: RoutePath.ADD_STORAGE,
    active: false,
  },
  {
    id: StepId.NETWORK_SETTINGS,
    name: getName(RoutePath.NETWORK_SETTINGS),
    path: RoutePath.NETWORK_SETTINGS,
    active: false,
  },
  {
    id: StepId.REVIEW,
    name: getName(RoutePath.REVIEW),
    path: RoutePath.REVIEW,
    active: false,
  },
];
