import { InjectionToken } from "@angular/core";

export const SMALL_DIALOG_WIDTH_VALUE = 500;
export const SMALL_DIALOG_WIDTH = new InjectionToken<number>(
  "SMALL_DIALOG_WIDTH"
);

export const MEDIUM_DIALOG_WIDTH_VALUE = 640;
export const MEDIUM_DIALOG_WIDTH = new InjectionToken<number>(
  "MEDIUM_DIALOG_WIDTH"
);

export const LARGE_DIALOG_WIDTH_VALUE = 800;
export const LARGE_DIALOG_WIDTH = new InjectionToken<number>(
  "LARGE_DIALOG_WIDTH"
);
