import { EMPTY_LINE } from "@sentium/constants";

import { isNumber } from "lodash";

export const isNumberAndNotNaN = (v: number): boolean =>
  isNumber(v) && !isNaN(v);

export function isNullOrUndefined<T>(v: T): boolean {
  return v === null || v === undefined;
}

export function isEmptyString(v: string): boolean {
  if (typeof v !== "string") {
    return true;
  } else {
    return v === EMPTY_LINE || v.trim().length === 0;
  }
}

export function removeObjectDuplicationsByKey<T>(arr: T[], key: keyof T) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function calculatePercentageOfValue({
  value,
  from,
}: {
  value: number;
  from: number;
}): number {
  return Math.floor((value * 100) / from);
}

export function roundValueToTheBiggestGb(v: number, limit = 1000): number {
  if (typeof v !== "number" || v < 0) {
    return 0;
  }

  let result = 0;
  if (v < limit) {
    const limiter = 10;
    const lessGbLimit = limit / limiter;
    let i = 0,
      initialValue = v;
    do {
      initialValue -= lessGbLimit;
      i = i + 1;
    } while (initialValue > 0);
    result = i;
    result = result / limiter;
  } else {
    let i = 0,
      initialValue = v;
    do {
      initialValue -= limit;
      i = i + 1;
    } while (initialValue > 0);
    result = i;
  }
  return result;
}
