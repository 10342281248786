const GREEN_LIMIT = 60;
const RED_LIMIT = 90;

const commonCalc = (v: number, base: string) => {
  if (v >= RED_LIMIT) {
    return `${base}-important`;
  }
  if (v < RED_LIMIT && v >= GREEN_LIMIT) {
    return `${base}-warn`;
  } else {
    return `${base}-ok`;
  }
};

/**
 * @description classes taken from libs\shared-assets\src\styles\blocks\_progressbar.scss:45-73
 */
export const progressBarUsedClass = (v: number): string =>
  commonCalc(v, "progressbar__progress");
export const progressBarPotentialClass = (v: number): string =>
  commonCalc(v, "progressbar__potentail");
