<label class="checkbox-switch">
  <input
    type="checkbox"
    class="switch-input"
    [checked]="selected"
    [disabled]="blocked"
    [readonly]="blocked"
    (change)="change($event)"
    [formControl]="valueControl"
  />
  <span
    class="checkbox-switch__switcher"
    [ngClass]="{ 'checkbox-switch__switcher-blocked': blocked }"
  ></span>
</label>
