enum SizeTypes {
  Unknown = "Unknown",
  Byte = "Byte",
  Bytes = "Bytes",
  KB = "KB",
  MB = "MB",
  GB = "GB",
  TB = "TB",
}
const BYTES_IN_KILOBYTE = 1024;
const BYTES_IN_MEGABYTE = BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE; // 1_048_576
const BYTES_IN_GIGABYTE =
  BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE; // 1_073_741_824
const BYTES_IN_TERABYTE =
  BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE; // 1_099_511_627_776

export {
  SizeTypes,
  BYTES_IN_KILOBYTE,
  BYTES_IN_MEGABYTE,
  BYTES_IN_GIGABYTE,
  BYTES_IN_TERABYTE,
};
