<ng-container
  *ngIf="isLoading; then loadingTmp; else tableContentTmp"
></ng-container>

<ng-template #loadingTmp>
  <div class="spinner-wrap">
    <sentium-spinner [size]="'tiny'"></sentium-spinner>
  </div>
</ng-template>

<ng-template #tableContentTmp>
  <ng-container
    *ngIf="dragable; then dragableTmp; else notDragableTmp"
  ></ng-container>

  <ng-template #dragableTmp>
    <div class="table-container">
      <mat-menu #colMenu="matMenu">
        <div cdkDropList (cdkDropListDropped)="reorderCols($event)">
          <div
            mat-menu-item
            cdkDrag
            *ngFor="let col of columns"
            (click)="$event.stopPropagation()"
          >
            <mat-checkbox
              [ngModel]="col.isActive"
              (ngModelChange)="col.isActive = $event; updateColumns()"
              >{{ col.key }}
            </mat-checkbox>
          </div>
        </div>
      </mat-menu>

      <div class="table-scroll-container">
        <mat-table
          cdkDropList
          matSort
          [dataSource]="dataSource"
          (cdkDropListDropped)="drop($event)"
        >
          <ng-container *ngFor="let column of columns">
            <ng-container [matColumnDef]="column.key">
              <ng-container *ngIf="!column.headerTemplate">
                <mat-header-cell *matHeaderCellDef>
                  <div
                    *ngIf="column.hasSorting"
                    mat-sort-header="{{ column.key }}"
                  >
                    {{ column.header }}
                  </div>
                  <ng-container *ngIf="!column.hasSorting">{{
                    column.header
                  }}</ng-container>
                </mat-header-cell>
              </ng-container>
              <ng-container *ngIf="column.headerTemplate">
                <mat-header-cell
                  *matHeaderCellDef="let element"
                  [ngClass]="{
                    'with-filter': column.hasFiltering,
                    'sticky-end': column?.stickyToTheEnd
                  }"
                >
                  <div
                    *ngIf="column.hasSorting"
                    mat-sort-header="{{ column.key }}"
                  >
                    <ng-template
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        $implicit: element
                      }"
                      [ngTemplateOutlet]="column.headerTemplate"
                    >
                    </ng-template>
                  </div>
                  <ng-container *ngIf="!column.hasSorting">
                    <ng-template
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        $implicit: element
                      }"
                      [ngTemplateOutlet]="column.headerTemplate"
                    >
                    </ng-template>
                  </ng-container>
                </mat-header-cell>
              </ng-container>

              <ng-container *ngIf="!column.cellTemplate">
                <mat-cell *matCellDef="let element">
                  {{ column.cell(element) }}
                </mat-cell>
              </ng-container>
              <ng-container *ngIf="column.cellTemplate">
                <mat-cell *matCellDef="let element">
                  <ng-template
                    [ngTemplateOutletContext]="{
                      column: column,
                      element: element,
                      $implicit: element
                    }"
                    [ngTemplateOutlet]="column.cellTemplate"
                  >
                  </ng-template>
                </mat-cell>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="drag">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row"
              ><span cdkDragHandle>Drag Me</span></mat-cell
            >
          </ng-container>

          <!-- Select Column -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef class="select-col">
              <mat-checkbox
                type="checkbox"
                (change)="$event && masterToggle()"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="select-col">
              <mat-checkbox
                type="checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event && selection.toggle(row)"
                (change)="selectionRow()"
                [checked]="selection.isSelected(row)"
              ></mat-checkbox>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="select-col"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="activeColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            cdkDrag
            [cdkDragData]="row"
            *matRowDef="let row; columns: activeColumns"
            [ngClass]="{ selected: selection.isSelected(row) }"
            (click)="itemSelected(row)"
          >
            <div class="placeholder" *cdkDragPlaceholder></div>
          </mat-row>

          <tr class="mat-row no-data-info" *matNoDataRow>
            <ng-template [ngTemplateOutlet]="noDatatemplate"></ng-template>
          </tr>
        </mat-table>
      </div>
    </div>
  </ng-template>

  <ng-template #notDragableTmp>
    <div class="table-container">
      <div class="table-scroll-container">
        <mat-table matSort [dataSource]="dataSource">
          <ng-container *ngFor="let column of columns">
            <ng-container [matColumnDef]="column.key">
              <ng-container *ngIf="!column.headerTemplate">
                <mat-header-cell *matHeaderCellDef>
                  <div
                    *ngIf="column.hasSorting"
                    mat-sort-header="{{ column.key }}"
                  >
                    {{ column.header }}
                  </div>
                  <ng-container *ngIf="!column.hasSorting">{{
                    column.header
                  }}</ng-container>
                </mat-header-cell>
              </ng-container>
              <ng-container *ngIf="column.headerTemplate">
                <mat-header-cell
                  *matHeaderCellDef="let element"
                  [ngClass]="{ 'with-filter': column.hasFiltering }"
                >
                  <div
                    *ngIf="column.hasSorting"
                    mat-sort-header="{{ column.key }}"
                  >
                    <ng-template
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        $implicit: element
                      }"
                      [ngTemplateOutlet]="column.headerTemplate"
                    >
                    </ng-template>
                  </div>
                  <ng-container *ngIf="!column.hasSorting">
                    <ng-template
                      [ngTemplateOutletContext]="{
                        column: column,
                        element: element,
                        $implicit: element
                      }"
                      [ngTemplateOutlet]="column.headerTemplate"
                    >
                    </ng-template>
                  </ng-container>
                </mat-header-cell>
              </ng-container>

              <ng-container *ngIf="!column.cellTemplate">
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="{ 'sticky-end': column?.stickyToTheEnd }"
                >
                  {{ column.cell(element) }}
                </mat-cell>
              </ng-container>
              <ng-container *ngIf="column.cellTemplate">
                <mat-cell
                  *matCellDef="let element"
                  [ngClass]="{ 'sticky-end': column?.stickyToTheEnd }"
                >
                  <ng-template
                    [ngTemplateOutletContext]="{
                      column: column,
                      element: element,
                      $implicit: element
                    }"
                    [ngTemplateOutlet]="column.cellTemplate"
                  >
                  </ng-template>
                </mat-cell>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Select Column -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef class="select-col">
              <mat-checkbox
                type="checkbox"
                (change)="$event && masterToggle()"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="select-col">
              <mat-checkbox
                type="checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event && selection.toggle(row)"
                (change)="selectionRow()"
                [checked]="selection.isSelected(row)"
              ></mat-checkbox>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="select-col"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="activeColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: activeColumns"
            [ngClass]="{ selected: !selectColumn && selection.isSelected(row) }"
            (click)="itemSelected(row)"
          >
          </mat-row>

          <tr class="mat-row no-data-info" *matNoDataRow>
            <ng-template [ngTemplateOutlet]="noDatatemplate"></ng-template>
          </tr>
        </mat-table>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="dataSource?.data && dataSource.data.length <= pageSize">
    <mat-paginator
      [hidePageSize]="hidePageSize"
      [showFirstLastButtons]="showFirstLastButtonsPaginator"
      [pageSize]="pageSize"
      [pageIndex]="currentPage"
      [pageSizeOptions]="rowsPerPage"
      [length]="dataSource.data.length"
    >
    </mat-paginator>
  </ng-template>
  <ng-template [ngIf]="dataSource?.data && dataSource.data.length > pageSize">
    <mat-paginator
      stylePaginator
      [hidePageSize]="hidePageSize"
      [showFirstLastButtons]="showFirstLastButtonsPaginator"
      [pageSize]="pageSize"
      [pageIndex]="currentPage"
      [pageSizeOptions]="rowsPerPage"
      [showTotalPages]="pageSize"
      [length]="dataSource.data.length"
    >
    </mat-paginator>
  </ng-template>
</ng-template>

<ng-template #noDatatemplate>
  <ng-content select="[noTableData]"></ng-content>
</ng-template>
