import { Directive, HostListener } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[stopClickPropagation]",
})
export class StopClickPropagationDirective {
  @HostListener("click", ["$event"]) onClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
