<div class="steps">
  <ng-container *ngFor="let item of items; let i = index">
    <div
      class="step"
      [ngClass]="{ 'step-active': !!item.active }"
      (click)="setActiveItem(i)"
    >
      <span class="step__text">{{ item.name | translate }}</span>
      <span class="step__arrow"></span>
    </div>
  </ng-container>
</div>
