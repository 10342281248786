import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SkipSelf,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import {
  DEFAULT_DEBOUNCE_TIME,
  METADATA_KEY_WEIGHT_LIMIT,
  EMPTY_LINE,
} from "@sentium/constants";
import { stringWeight } from "@sentium/common-utils";
import { Metadata } from "@sentium/models";

import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";
import { cloneDeep } from "lodash";

import { MetadataContainerService } from "../metadata-container.service";

@Component({
  selector: "sentium-existing-metadata",
  templateUrl: "./existing-metadata.component.html",
  styleUrls: ["./existing-metadata.component.scss"],
})
export class ExistingMetadataComponent implements OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private _initExistingMetadata!: Array<Metadata>;
  private _visibleExistingMetadata!: Array<Metadata>;

  private formWeigthLimit: { [key: string]: boolean } = {};

  existingForm = new FormGroup({});

  filter = new FormControl(EMPTY_LINE);

  @Input() set existingMetadata(v: Metadata[] | null) {
    if (!v) {
      return;
    }
    this._initExistingMetadata = cloneDeep(v);
    this._visibleExistingMetadata = cloneDeep(v);
    this.createForm();
  }

  get someMetadataOutOfLimit(): boolean {
    const visibleKeys = this._visibleExistingMetadata.map((e) => e.key);
    const visibleFormWeigthLimit: { [key: string]: boolean } = {};
    Object.values(visibleKeys)
      .filter((key) => visibleKeys.includes(key))
      .forEach(
        (key) => (visibleFormWeigthLimit[key] = this.formWeigthLimit[key])
      );
    return Object.values(visibleFormWeigthLimit).some((f) => f);
  }

  set visibleExistingMetadata(v: Array<Metadata>) {
    this._visibleExistingMetadata = cloneDeep(v);
  }
  get visibleExistingMetadata(): Array<Metadata> {
    return this._visibleExistingMetadata;
  }

  existingMetadata$!: Observable<Array<Metadata>>;

  @Output() existingMetadataEvent = new EventEmitter<
    Array<Pick<Metadata, "key" | "value">>
  >();

  @Output() existingMetadataInvalidWeight = new EventEmitter<boolean>();

  constructor(
    @SkipSelf()
    private readonly metadataContainerService: MetadataContainerService
  ) {
    this.formSubscription();
    this.searchSubscription();
  }

  ngOnChanges(changes: { existingMetadata: SimpleChange }): void {
    this.existingMetadataEvent.emit(changes.existingMetadata.currentValue);
  }

  removeItem(item: Metadata) {
    this.existingForm.removeControl(item.key, { emitEvent: true });
    this.metadataContainerService.removeExistingMeta(item);
  }

  private createForm(): void {
    this._initExistingMetadata.forEach((meta) => {
      const { key, value } = { ...meta };
      this.formWeigthLimit[key] = false;
      this.existingForm.addControl(key, new FormControl(value), {
        emitEvent: true,
      });
    });
    this.emitInvaliStatus();
  }

  readonly limitWeigthByKey = (key: string): boolean =>
    this.formWeigthLimit[key];

  private formSubscription(): void {
    this.existingForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe((props: { [key: string]: string }) => {
        for (const [key, value] of Object.entries(props)) {
          this.formWeigthLimit[key] =
            stringWeight(key) + stringWeight(value) >=
            METADATA_KEY_WEIGHT_LIMIT * 2;
        }
        this.emitInvaliStatus();
        this.emitValues(props);
      });
  }

  private searchSubscription(): void {
    this.filter.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(EMPTY_LINE),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        map((filter: string) =>
          filter === EMPTY_LINE
            ? this._initExistingMetadata
            : this._initExistingMetadata.filter((meta) =>
                meta.key.toLowerCase().includes(filter.toLowerCase())
              )
        )
      )
      .subscribe((v) => (this._visibleExistingMetadata = cloneDeep(v)));
  }

  private emitInvaliStatus(): void {
    const rules: boolean[] = Object.values(this.formWeigthLimit);
    this.existingMetadataInvalidWeight.emit(rules.some((f) => f));
  }

  private emitValues(props: { [key: string]: string }) {
    const values: Metadata[] = cloneDeep(
      this.metadataContainerService.existingMetadata
    );
    values.forEach((e) => (e.value = props[e.key]));
    this.existingMetadataEvent.emit(values);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
