/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateRef } from "@angular/core";

export class ColumnDef<T> {
  key = "";
  header = "";
  footer = "";
  cell!: (element: T) => any;
  stickyToTheEnd = false;
  isActive = true;
  hasFiltering = true;
  hasSorting = true;
  headerTemplate: TemplateRef<any> | null = null;
  cellTemplate: TemplateRef<any> | null = null;
  footerTemplate: TemplateRef<any> | null = null;
  alwaysDisplayed = false;
}
