import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { GlobalState, IEnvironment } from "@sentium/models";
import { generalIsLoading, setToken, setProjectId } from "@sentium/store";
import { ENV_TOKEN } from "@sentium/constants";

import { MyInterceptorFlag } from "./http.model";

@Injectable({
  providedIn: "root",
})
export class TokenFacadeService {
  private readonly TOKEN_HEADER_NAME = "x-subject-token";
  constructor(
    @Inject(ENV_TOKEN) private readonly env: IEnvironment,
    private readonly http: HttpClient,
    private readonly store$: Store<GlobalState>
  ) {}

  getToken(name = "frontend", password = "tGNLmnjAiVM7JSL"): void {
    const url = `${this.env.identity}/auth/tokens`;
    const payload = {
      auth: {
        identity: {
          methods: ["password"],
          password: {
            user: {
              name,
              domain: {
                name: "Default",
              },
              password,
            },
          },
        },
        scope: {
          project: {
            name: "FrontEnd",
            domain: { id: "default" },
          },
        },
      },
    };
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Headers", "*")
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set("Access-Control-Expose-Headers", "*");

    this.http
      .post(
        url,
        { ...payload },
        {
          headers,
          observe: "response",
          params: { [MyInterceptorFlag.SKIP_TOKEN_ASSIGN]: true },
        }
      )
      .subscribe((response: HttpResponse<any>) => {
        const projectId = (
          response.body as { token: { project: { id: string } } }
        ).token.project.id;
        this.store$.dispatch(
          setToken({
            token: response.headers.get(this.TOKEN_HEADER_NAME) as string,
          })
        );
        this.store$.dispatch(setProjectId({ id: projectId }));
        this.store$.dispatch(generalIsLoading({ flag: false }));
      });
  }
}
