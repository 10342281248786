import { InjectionToken } from "@angular/core";
import { CodeEditorMode, CodeEditorOptions, CodeEditorTheme } from "../model";

export const DEFAULT_CONFIG: Partial<CodeEditorOptions> = {
  theme: CodeEditorTheme.DEFAULT,
  mode: CodeEditorMode.SHELL,
  lineNumbers: true,
  lineWrapping: true,
  foldGutter: true,
  gutters: [
    "CodeMirror-linenumbers",
    "CodeMirror-foldgutter",
    "CodeMirror-lint-markers",
  ],
  autoCloseBrackets: true,
  matchBrackets: true,
  lint: true,
};

export const DEFAULT_EDITOR_CONFIG = new InjectionToken<
  Partial<CodeEditorOptions>
>("DEFAULT_EDITOR_CONFIG");
