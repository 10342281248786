import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { getToken } from "@sentium/store";
import { GlobalState } from "@sentium/models";
import { EMPTY_LINE } from "@sentium/constants";

import { filter, Observable, Subject, switchMap, takeUntil } from "rxjs";

import { MyInterceptorFlag } from "../http.model";
import { HeaderName } from "./header-name";

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store$: Store<GlobalState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipRules = [
      request.url.startsWith("./assets/"),
      Boolean(request.params.get(MyInterceptorFlag.SKIP_TOKEN_ASSIGN)),
    ];

    if (skipRules.some((f) => f)) {
      return next.handle(request);
    } else {
      return this.store$.select(getToken).pipe(
        takeUntil(this.destroy$),
        filter((token) => token !== EMPTY_LINE),
        switchMap((token) => {
          return next.handle(
            request.clone({
              headers: request.headers.append(
                HeaderName.AUTH_TOKEN_HEADER,
                token
              ),
            })
          );
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
