import { Injectable } from "@angular/core";

import { MetadataNode, Metadata } from "@sentium/models";
import { EMPTY_LINE, NOT_FOUND_ID } from "@sentium/constants";

import { cloneDeep } from "lodash";

import { findNodeByKey, restoreDeletedNode } from "./util";

@Injectable()
export class MetadataContainerService {
  private _initAvailableMetadata: Array<MetadataNode> = [];
  private _availableMetadata: Array<MetadataNode> = [];

  private _existingMetadata: Array<Metadata> = [];

  set availableMetadata(v: MetadataNode[]) {
    this._availableMetadata = cloneDeep(v);
  }

  get availableMetadata(): Array<MetadataNode> {
    return this._availableMetadata;
  }

  set initAvailableMetadata(v: MetadataNode[]) {
    this._initAvailableMetadata = cloneDeep(v);
  }

  get initAvailableMetadata(): Array<MetadataNode> {
    return this._initAvailableMetadata;
  }

  set customMetadata(meta: Pick<Metadata, "key" | "takenFromAvailable">) {
    const { key, takenFromAvailable } = { ...meta };
    const allValues = cloneDeep(this.existingMetadata);
    const index = allValues.map((e) => e.key).indexOf(key);
    // skip adding duplicates
    if (index === NOT_FOUND_ID) {
      allValues.push({ key, value: EMPTY_LINE, takenFromAvailable });
      this._existingMetadata = cloneDeep(allValues);
    }
  }

  set existingMetadata(v: Array<Metadata>) {
    this._existingMetadata = cloneDeep(v);
  }

  get existingMetadata(): Metadata[] {
    return this._existingMetadata;
  }

  removeExistingMeta(item: Metadata): void {
    let allValues = cloneDeep(this._existingMetadata);
    allValues = allValues.filter((v) => v.key !== item.key);
    this._existingMetadata = cloneDeep(allValues);
    if (item.takenFromAvailable) {
      this.restoreItemInAvailableMetadata(item);
    }
  }

  private restoreItemInAvailableMetadata(item: Metadata) {
    const foundNodeFromInitialMeta = findNodeByKey(
      this.initAvailableMetadata[0],
      "name",
      item.key
    );
    if (foundNodeFromInitialMeta) {
      const foundParentOfNode = findNodeByKey(
        this.initAvailableMetadata[0],
        "nodeId",
        foundNodeFromInitialMeta.parentNodeId as string
      );
      if (foundParentOfNode) {
        const restored = restoreDeletedNode(
          this.availableMetadata[0],
          foundParentOfNode,
          foundNodeFromInitialMeta
        );
        this.availableMetadata = cloneDeep(restored);
      }
    }
  }
}
