<ng-container
  *ngIf="iconsService.iconExists(icon); then svgIconTmp; else fontIconTmp"
>
</ng-container>

<ng-template #svgIconTmp>
  <ng-container
    *ngIf="disabled; then disabledSvgIconTmp; else notDisabledSvgIconTmp"
  >
  </ng-container>
  <ng-template #disabledSvgIconTmp>
    <button mat-icon-button [disabled]="disabled">
      <mat-icon [svgIcon]="icon" [ngStyle]="iconStyle"></mat-icon>
    </button>
  </ng-template>
  <ng-template #notDisabledSvgIconTmp>
    <mat-icon [svgIcon]="icon" [ngStyle]="iconStyle"></mat-icon>
  </ng-template>
</ng-template>

<ng-template #fontIconTmp>
  <ng-container
    *ngIf="disabled; then disabledFontIconTmp; else notDisabledFontIconTmp"
  >
  </ng-container>
  <ng-template #disabledFontIconTmp>
    <button mat-icon-button [disabled]="disabled">
      <mat-icon class="font-icon" [ngStyle]="iconStyle">{{ icon }}</mat-icon>
    </button>
  </ng-template>
  <ng-template #notDisabledFontIconTmp>
    <mat-icon class="font-icon" [ngStyle]="iconStyle">{{ icon }}</mat-icon>
  </ng-template>
</ng-template>
