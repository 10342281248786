<div class="box flex-grow-1">
  <div class="box-header">
    <div class="row g-0 align-items-baseline">
      <div class="col-5">
        <div class="block__title mb-0 me-8">Existing Metadata</div>
      </div>
      <div class="col-7">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Filter</mat-label>
            <input
              matInput
              type="text"
              placeholder="Filter"
              [formControl]="filter"
            />
            <button matSuffix mat-icon-button>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="box-main">
    <form [formGroup]="existingForm">
      <ng-container *ngFor="let item of visibleExistingMetadata">
        <div class="box-main__content">
          <span
            [ngClass]="{
              badge: !limitWeigthByKey(item.key),
              'error-badge': limitWeigthByKey(item.key)
            }"
          >
            <div class="badge__content">{{ item.key }}</div>
            <input
              type="text"
              class="box-input w-100"
              placeholder="Value"
              [formControlName]="item.key"
            />
            <sentium-icon
              icon="close"
              (click)="removeItem(item)"
            ></sentium-icon>
          </span>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="bytes-limit-text" *ngIf="someMetadataOutOfLimit">
    <p>The maximum size of the metadata key and value is 255 bytes each</p>
  </div>
</div>
