import {
  BYTES_IN_GIGABYTE,
  BYTES_IN_KILOBYTE,
  BYTES_IN_MEGABYTE,
  BYTES_IN_TERABYTE,
  SizeTypes,
} from "@sentium/models";
import { isNullOrUndefined } from "./common";

/**
 * @param str value weight should be calculated
 * @returns the bytes numbers of string input
 */
export function stringWeight(str: string): number {
  return new Blob([str]).size;
}

/**
 *
 * @param v value needed to convert from MB to GB
 * @returns value divided by 1024
 */
export function convertMbToGb(v: number): number {
  return Number(parseFloat(`${v / BYTES_IN_KILOBYTE}`).toFixed(2));
}

/**
 * @param bytes size of loaded image in bytes.
 * @returns Size of image in format { value: SIZE, type: TYPE_OF_SIZE }.
 */
export function bytesToSize(bytes: number): { value: number; type: SizeTypes } {
  const sizes = [
    SizeTypes.Bytes,
    SizeTypes.KB,
    SizeTypes.MB,
    SizeTypes.GB,
    SizeTypes.TB,
  ];
  if (isNullOrUndefined(bytes)) {
    return { type: SizeTypes.Unknown, value: -1 };
  }
  if (bytes === 1 || bytes === 0) {
    return { value: bytes, type: SizeTypes.Byte };
  }
  const i = parseInt(
    `${Math.floor(Math.log(Math.abs(bytes)) / Math.log(BYTES_IN_KILOBYTE))}`,
    10
  );
  if (i === 0) {
    return { value: bytes, type: sizes[i] };
  }
  return {
    value: +(bytes / BYTES_IN_KILOBYTE ** i).toFixed(1),
    type: sizes[i],
  };
}

/**
 * @param num - count of type that should be converted.
 * @param type - type from which should be converted to bytes.
 * @returns converted bytes.
 */
export function typeToBytes(num: number, type: SizeTypes) {
  if (num === 0) {
    return 0;
  }

  return (
    {
      Byte: 1,
      Bytes: num,
      KB: num * BYTES_IN_KILOBYTE, // 1 KB = 1024 Bytes. Count of KB should be converted to bytes
      MB: num * BYTES_IN_MEGABYTE, // 1 KB = 1048576 Bytes. Count of MB should be converted to bytes
      GB: num * BYTES_IN_GIGABYTE, // 1 KB = 1073741824 Bytes. Count of KB should be converted to bytes
      TB: num * BYTES_IN_TERABYTE,
      Unknown: -1,
    }[type] || -1
  );
}

export function convertFromToSize(v: number, from: SizeTypes, to: SizeTypes) {
  const range = [
    SizeTypes.Bytes,
    SizeTypes.KB,
    SizeTypes.MB,
    SizeTypes.GB,
    SizeTypes.TB,
  ];
  const indexFrom = range.indexOf(from);
  const indexTo = range.indexOf(to);
  const diff = indexTo - indexFrom;
  let value = v;
  for (let i = 0; i < diff; i++) {
    (function (valueNumber) {
      value = Number(
        parseFloat(`${valueNumber / BYTES_IN_KILOBYTE}`).toFixed(2)
      );
    })(value);
  }
  return value;
}
