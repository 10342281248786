import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { DateFilterFn } from "@angular/material/datepicker";
import { isItWeekEndDay } from "@sentium/common-utils";
import { DEFAULT_DEBOUNCE_TIME } from "@sentium/constants";

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from "rxjs";

import * as moment from "moment";

@Component({
  selector: "sentium-simple-calendar",
  templateUrl: "./simple-calendar.component.html",
  styleUrls: ["./simple-calendar.component.scss"],
})
export class SimpleCalendarComponent<T extends moment.Moment>
  implements OnDestroy
{
  private readonly destroy$ = new Subject<void>();
  private readonly dateInput$ = new Subject<T | null>();

  @Input() label!: string;
  @Input() hint!: string;
  @Input() placeholder!: string;

  @Input() minDate!: T;
  @Input() maxDate!: T;

  @Input() appearance: MatFormFieldAppearance = "fill";

  @Input() widthFull = true;
  @Input() disableWeekend = false;
  @Input() customBottomSection = false;
  @Input() withSuffixIcon = true;

  @Input() set selectedDate(date: T) {
    this.dateControl.setValue(date, { emitEvent: true });
  }

  @Output() dateChangeEvent = new EventEmitter<T | null>();

  dateControl = new FormControl();

  constructor() {
    this.initSubscriptions();
  }

  filterDate: DateFilterFn<T | null> = (date: T | null) => {
    if (!date) {
      return true;
    }
    // if the date is Saturday of Sunday - disable this date being selected on calendar
    return this.disableWeekend ? !isItWeekEndDay(date) : true;
  };

  dateInput(date: T | null): void {
    this.dateInput$.next(date);
  }

  private initSubscriptions(): void {
    this.dateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: T) => this.dateInput$.next(date));

    this.dateInput$
      .pipe(
        takeUntil(this.destroy$),
        filter((date: T | null) => Boolean(date)),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe((date: T | null) => this.dateChangeEvent.emit(date));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
