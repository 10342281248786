import { ComponentType } from "@angular/cdk/portal";
import { Inject, Injectable, Optional, TemplateRef } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";

import { DialogType } from "./dialog.model";

import * as token from "./token";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private readonly smallDialogWidth = 500;
  private readonly mediumDialogWidth = 640;
  private readonly largeDialogWidth = 800;

  private readonly defaultConfing: MatDialogConfig = {
    autoFocus: true,
    panelClass: ["dialog-container"],
  };

  constructor(
    private readonly dialog: MatDialog,
    @Optional()
    @Inject(token.SMALL_DIALOG_WIDTH)
    private readonly defaultSmallDialogWidth: number,
    @Optional()
    @Inject(token.MEDIUM_DIALOG_WIDTH)
    private readonly defaultMediumDialogWidth: number,
    @Optional()
    @Inject(token.LARGE_DIALOG_WIDTH)
    private readonly defaultLargeDialogWidth: number
  ) {}

  open<Type, DataType, ReturnType>(
    component: ComponentType<Type> | TemplateRef<Type>,
    config: MatDialogConfig<DataType> = {},
    type: DialogType = "medium"
  ): MatDialogRef<Type, ReturnType> {
    const { panelClass = [], ...restConfig } = config;
    const configType = {
      ...this.defaultConfing,
      width: config?.width ?? this.getWidth(type, config?.width),
    };
    const conf = {
      ...configType,
      panelClass: [
        ...(configType.panelClass as string | string[]),
        ...panelClass,
      ],
      ...restConfig,
    };

    return this.dialog.open<Type, DataType, ReturnType>(component, conf);
  }

  private getWidth(type: DialogType, widthByUser?: string): string {
    if (widthByUser) {
      return widthByUser;
    }
    return `${
      {
        small: this.defaultSmallDialogWidth ?? this.smallDialogWidth,
        medium: this.defaultMediumDialogWidth ?? this.mediumDialogWidth,
        large: this.defaultLargeDialogWidth ?? this.largeDialogWidth,
      }[type]
    }`;
  }
}
