<div class="progressbar-block">
  <div
    class="progressbar-block__text d-flex justify-content-between align-items-center"
  >
    <div class="progressbar-block__title">{{ title }}</div>
    <div class="progressbar-block__value">
      {{ infoTitle }}
      <ng-container *ngIf="infoSubTitle">
        <span class="gray-text">{{ infoSubTitle }}</span>
      </ng-container>
    </div>
  </div>
  <div class="progressbar">
    <ng-container *ngIf="potential">
      <span
        class="progressbar__potentail"
        [ngClass]="potentialClass(potential)"
        [ngStyle]="{ width: potentialPercentage }"
      ></span>
    </ng-container>
    <span
      class="progressbar__progress"
      [ngClass]="progressClass(progress)"
      [ngStyle]="{ width: progressPercentage }"
    ></span>
  </div>
</div>
