/* eslint-disable @typescript-eslint/no-explicit-any */
import * as moment from "moment";
import {
  DATE_FIRST_NANOSECOND,
  DATE_FORMAT_WITH_NANOSECONDS,
  DATE_LAST_NANOSECOND,
  DEV_DATE_FORMAT,
} from "@sentium/constants";

function isDateAfter(init: string, compare: string): boolean;
function isDateAfter(init: number, compare: number): boolean;
function isDateAfter(init: Date, compare: Date): boolean;

function isDateAfter(init: any, compare: any): boolean {
  const stringAndNumberDateTransform = () => {
    return { initDate: new Date(init), compareDate: new Date(compare) };
  };
  if (typeof init === "string" && typeof compare === "string") {
    const { initDate, compareDate } = stringAndNumberDateTransform();
    return initDate.getTime() > compareDate.getTime();
  }

  if (typeof init === "number" && typeof compare === "number") {
    const { initDate, compareDate } = stringAndNumberDateTransform();
    return initDate.getTime() > compareDate.getTime();
  }

  return init.getTime() > compare.getTime();
}

function isItWeekEndDay(currentDate: moment.Moment | null): boolean {
  if (!currentDate) {
    return true;
  }
  const curentDay = currentDate.day();
  return curentDay === 0 || curentDay === 6;
}

function daysDifferenceBetweenDates(startDate: string, endDate: string): number;
function daysDifferenceBetweenDates(startDate: Date, endDate: Date): number;

function daysDifferenceBetweenDates(startDate: any, endDate: any): number {
  let difference: number;
  if (typeof startDate === "string" && typeof endDate === "string") {
    difference = new Date(startDate).getTime() - new Date(endDate).getTime();
  } else {
    difference = startDate.getTime() - endDate.getTime();
  }
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return Math.abs(days);
}

function addDays(numOfDays: number, date = new Date()): Date {
  date.setDate(date.getDate() + numOfDays);
  return date;
}

function addMonths(numOfMonths: number, date = new Date()): Date {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
}

function convertWithNanoSeconds(date: string): string {
  return moment(new Date(date).toDateString()).format(
    DATE_FORMAT_WITH_NANOSECONDS
  );
}

function getDateWithFirstNanosecond(date: string): string {
  const formatedDate = moment(new Date(date).toDateString()).format(
    DEV_DATE_FORMAT
  );
  return `${formatedDate}${DATE_FIRST_NANOSECOND}`;
}

function getDateWithLastNanosecond(date: string): string {
  const formatedDate = moment(new Date(date).toDateString()).format(
    DEV_DATE_FORMAT
  );
  return `${formatedDate}${DATE_LAST_NANOSECOND}`;
}

export {
  isDateAfter,
  isItWeekEndDay,
  daysDifferenceBetweenDates,
  addDays,
  addMonths,
  convertWithNanoSeconds,
  getDateWithFirstNanosecond,
  getDateWithLastNanosecond,
};
