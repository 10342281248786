import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "@sentium/material";

import { IconComponent } from "./icon.component";

const COMPONENTS = [IconComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MaterialModule],
  exports: [...COMPONENTS],
})
export class IconsModule {}
