<ng-container *ngIf="shouldBeShown()">
  <header class="header">
    <div
      class="container d-flex justify-content-between align-items-center gap-30"
    >
      <a [href]="'/' + mainPath">
        <img src="/assets/img/Logo.png" alt="logo" />
      </a>
      <nav>
        <ul class="header-nav__list d-flex gap-30">
          <li class="header-nav__item">
            <button
              class="dropbtn d-flex align-items-center"
              [matMenuTriggerFor]="usersMenu"
            >
              <sentium-icon icon="terminal"></sentium-icon>
              <span class="me-5 ms-10">piroman441</span>
              <sentium-icon icon="small-arrow-down"></sentium-icon>
            </button>

            <mat-menu #usersMenu="matMenu">
              <ng-container *ngFor="let item of userLinks">
                <div mat-menu-item>
                  <span>{{ item.name }}</span>
                </div>
              </ng-container>
            </mat-menu>
          </li>

          <li class="header-nav__item">
            <div class="dropdown">
              <button
                class="dropbtn d-flex align-items-center"
                [matMenuTriggerFor]="regionMenu"
              >
                <span class="me-5">Tokyo</span>
                <sentium-icon icon="small-arrow-down"></sentium-icon>
              </button>
              <mat-menu #regionMenu="matMenu">
                <ng-container *ngFor="let item of regionLinks">
                  <div mat-menu-item>
                    <span>{{ item.name }}</span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </li>
          <li class="header-nav__item">
            <div class="dropdown">
              <button
                class="dropbtn d-flex align-items-center"
                [matMenuTriggerFor]="languagesMenu"
              >
                <span class="me-5"> {{ displayLanguage | translate }}</span>
                <sentium-icon icon="small-arrow-down"></sentium-icon>
              </button>
              <mat-menu #languagesMenu="matMenu">
                <ng-container *ngFor="let item of languagesLinks">
                  <div mat-menu-item (click)="changeLanguage(item)">
                    <span>{{ item.name | translate }}</span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </li>
          <li class="header-nav__item">
            <div class="dropdown">
              <button
                class="dropbtn d-flex align-items-center"
                [matMenuTriggerFor]="supportMenu"
              >
                <span class="me-5">Support</span>
                <sentium-icon icon="small-arrow-down"></sentium-icon>
              </button>
              <mat-menu #supportMenu="matMenu">
                <ng-container *ngFor="let item of supportLinks">
                  <div mat-menu-item>
                    <span>{{ item.name }}</span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</ng-container>
