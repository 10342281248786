import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@sentium/material";

import { IconsModule } from "../icons";

import { AvailableMetadataComponent } from "./available-metadata/available-metadata.component";
import { ExistingMetadataComponent } from "./existing-metadata/existing-metadata.component";
import { MetadataComponent } from "./metadata/metadata.component";

const COMPONENTS = [
  MetadataComponent,
  AvailableMetadataComponent,
  ExistingMetadataComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IconsModule,
  ],
  exports: [...COMPONENTS],
})
export class MetadataModule {}
